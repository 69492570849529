import React, { useEffect, useState } from "react";

import _uniqueId from "lodash/uniqueId";

import "./styles.scss";
import { ToggleOption, ToggleProps } from "./types";

const MultipleOptionsToggle = (props: ToggleProps) => {
  const {
    options, onChange, setChatVideoStatus, ...rest
  } = props;
  const [activeId, setActiveId] = useState<string | undefined>(options.find((option) =>
    option.isActive)?.id);

  const onInputChange = (id: string, videoSetting = false) => {
    if (setChatVideoStatus) {
      setChatVideoStatus(videoSetting);
    }
    setActiveId(id);
    if (onChange) {
      onChange(id);
    }
  };

  useEffect(() => {
    const { isVideo = false } = options.find((option) =>
      option.isActive) || {};

    if (setChatVideoStatus) {
      setChatVideoStatus(isVideo);
    }
  }, []);

  return (
    <div className="multiple-options-toggle" {...rest}>
      {options.map((option: ToggleOption) => {
        const { id, label, isVideo = false } = option;
        const checkboxUniqueId = _uniqueId("checkbox_");
        return (
          <React.Fragment key={id}>
            <input
              type="radio"
              name="toggle"
              id={checkboxUniqueId}
              value=""
              className="multiple-options-toggle__input"
              checked={id === activeId}
              onChange={() =>
                onInputChange(id, isVideo)}
            />
            <label className="multiple-options-toggle__button" htmlFor={checkboxUniqueId}>
              {label}
            </label>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default MultipleOptionsToggle;
