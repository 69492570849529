import React, { useState } from "react";

import { popularQuestionsData } from "../../constants";
import { PopularQuestionsData } from "../../types";

import { PopularQuestionsProps } from "./types";

import "./styles.scss";

const PopularQuestions = ({ checkedThemeId, nextStep, marketingOnboardingAnswersHandler }: PopularQuestionsProps) => {
  const [checkedQuestionId, setCheckedQuestionId] = useState<number | undefined>();

  const questionsKey = `questions_${checkedThemeId}` as keyof PopularQuestionsData;
  const questions = popularQuestionsData[questionsKey];

  const clickQuestionCardHandler = (questionId: number, clientChoiceQuestion: string) => {
    if (questions && clientChoiceQuestion) {
      marketingOnboardingAnswersHandler(`тема «${questions?.categoryName}»`, `ответ-${questionId}`);
    }
    setCheckedQuestionId(questionId);
    nextStep();
  };
  return (
    <div className="popular-questions">
      <p className="onboarding-title">
        {checkedThemeId === 4
          ? (
            <>
              Популярные вопросы
              <br />
              на другие темы
            </>
          )
          : (
            <>
              Популярные вопросы
              <br />
              на тему «
              {questions?.categoryName}
              »
            </>
          )}
      </p>

      <div className="popular-questions__list-container">
        {questions?.questions.map(({ question, id }) =>
          (
            <div
              className="popular-questions__list-item"
              onClick={() =>
                clickQuestionCardHandler(id, question)}
            >
              <input
                className="big"
                type="radio"
                name="question"
                checked={id === checkedQuestionId}
              />
              <p>{question}</p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default PopularQuestions;
