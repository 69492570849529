import { IconTypeEnum } from "@/components/common/icon";

import commentsImg1 from "./components/commentsFromClients/images/img-1.png";
import commentsImg2 from "./components/commentsFromClients/images/img-2.png";
import commentsImg3 from "./components/commentsFromClients/images/img-3.png";
import commentsImg4 from "./components/commentsFromClients/images/img-4.png";
import commentsImg5 from "./components/commentsFromClients/images/img-5.png";
import commentsImg6 from "./components/commentsFromClients/images/img-6.png";
import commentsImg7 from "./components/commentsFromClients/images/img-7.png";
import { PopularQuestionsData } from "./types";

export const questions = {
  step1: [
    {
      icon: IconTypeEnum.Smile_1,
      text: "Спокойно",
    },
    {
      icon: IconTypeEnum.Smile_2,
      text: "Тревожно",
    },
    {
      icon: IconTypeEnum.Smile_3,
      text: "Растерянно",
    },
    {
      icon: IconTypeEnum.Smile_4,
      text: "Грустно",
    },
    {
      icon: IconTypeEnum.Smile_5,
      text: "Взволнованно",
    },
    {
      icon: IconTypeEnum.Smile_6,
      text: "Раздражённо",
    },
  ],
  step2: [
    {
      icon: IconTypeEnum.Topic_icon_2,
      text: "Текущие \nотношения ",
    },
    {
      icon: IconTypeEnum.Topic_icon_3,
      text: `Любовный \nтреугольник`,
    },
    {
      icon: IconTypeEnum.Topic_icon_1,
      text: "В поисках \nлюбви",
    },
    {
      icon: IconTypeEnum.Topic_icon_4,
      text: "Другой\nвопрос",
    },
  ],
};

export const popularQuestionsData: PopularQuestionsData = {
  questions_1: {
    categoryName: "Текущие отношения",
    questions: [
      { id: 1, question: "Истинные чувства, мысли и намерения партнёра?" },
      { id: 2, question: "Как мне поступить сейчас, чтобы улучшить наши отношения?" },
      { id: 3, question: "Что он на самом деле хочет от меня?" },
      { id: 4, question: "Есть ли у нас будущее, или это всё — конец?" },
      { id: 5, question: `Как он меня видит, и какие у него искренние чувства ко мне?` },
      { id: 6, question: "Стоит ли мне ему доверять? Лжёт ли он мне?" },
      { id: 6, question: "Задам свой вопрос" },
    ],
  },
  questions_2: {
    categoryName: "Любовный треугольник",
    questions: [
      { id: 1, question: "Изменяет ли он мне? Как это узнать?" },
      { id: 2, question: "Кого он выберет: меня или другую?" },
      { id: 3, question: "Будем ли мы вместе в будущем, если у него есть другая женщина?" },
      { id: 4, question: "Каковы его чувства к своей супруге?" },
      { id: 5, question: `Разведётся ли он со своей женой ради меня?` },
      { id: 6, question: `Чувствует ли он что-то к сопернице?` },
      { id: 7, question: "Задам свой вопрос" },
    ],
  },
  questions_3: {
    categoryName: "В поисках любви",
    questions: [
      { id: 1, question: "Почему не получается встретить свою любовь?" },
      { id: 2, question: "Как понять, что я готова к новым отношениям?" },
      { id: 3, question: "Что мешает мне любить и быть любимой?" },
      { id: 4, question: "Как избежать повторения ошибок из прошлых отношений?" },
      { id: 5, question: "Какой урок мне нужно вынести из своего опыта?" },
      { id: 6, question: "Как привлечь в свою жизнь здоровые и крепкие отношения?" },
      { id: 7, question: "Задам свой вопрос" },
    ],
  },
  questions_4: {
    categoryName: "Другой вопрос",
    questions: [
      { id: 1, question: "Когда мне стоит взять отпуск и как его провести?" },
      { id: 2, question: "Исполнится ли моё желание?" },
      { id: 3, question: "Что мне сделать, чтобы чувствовать себя лучше?" },
      { id: 4, question: "Что меня ждёт в ближайший месяц?" },
      { id: 5, question: "Как мне благоприятнее действовать в моей ситуации?" },
      { id: 6, question: "Задам свой вопрос" },
    ],
  },
};

export const commentsFromClientsData = [
  {
    id: 1,
    img: commentsImg1,
    clientName: "Нелля, 33 года",
    rate: 5,
    date: "20 апр 2024",
    comment: "В первый раз обращалась к Таро, так как в отношениях происходил кризис. Я была в шоке, насколько карты показывали ситуацию, после мы обсудили векторы развития и в каком направлении двигаться. Успокоилась, отпустила ситуацию и занялась собой. Спустя некоторое время прислушалась к советам, и на следующем сеансе ситуация при раскладе изменилась на 360. И дело не в картах, а в действиях и мышлении + работа над собой. Ведь когда фокусируешься на себе и в мыслях порядок, все вокруг меняется. Спасибо ОГРОМНОЕ за советы и поддержку! ОЧЕНЬ рекомендую 🙌🏽",
  },
  {
    id: 2,
    img: commentsImg2,
    clientName: "Ольга, 35 лет",
    rate: 5,
    date: "6 мая 2024",
    comment: "Не понимала, почему ссоримся из-за мелочей. Оказалось, страхи из прошлого мешали нам развиваться.",
  },
  {
    id: 3,
    img: commentsImg3,
    clientName: "Ирина, 42 года",
    rate: 5,
    date: "20 фев 2024",
    comment: "Мне казалось, что все мои отношения разрушены. После расклада я увидела, что многое зависит от меня. Мы начали новый этап",
  },
  {
    id: 4,
    img: commentsImg4,
    clientName: "Лена, 29 лет",
    rate: 5,
    date: "4 апр 2024",
    comment: "Постоянно выбирала не тех мужчин. Расклад помог увидеть, почему это происходит, и я перестала бояться новых знакомств.",
  },
  {
    id: 5,
    img: commentsImg5,
    clientName: "Имя скрыто",
    rate: 5,
    date: "20 фев 2024",
    comment: "Спасибо большое за консультацию! Зашла на сайт из любопытства и за 5 минут услышала характеристику себя, партнера и четкий ответ на поставленный вопрос! Очень интересный опыт для меня. Спасибо!",
  },
  {
    id: 6,
    img: commentsImg6,
    clientName: "Юлия, 38 лет",
    rate: 5,
    date: "4 апр 2024",
    comment: "Спасибо большое, что подсветили мою проблему. К сожалению я так жила много лет и меняя отношение к этому сейчас, попался не тот мужчина. Думаю смогу найти гармонию, не потеряв себя, с моим будущим партнёром)",
  },
  {
    id: 7,
    img: commentsImg7,
    clientName: "Александра",
    rate: 5,
    date: "4 апр 2024",
    comment: "Ирин увидела точные эмоции и положение в моей личной жизни. Рекомендую!",
  },
];
