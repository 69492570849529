import React, { useEffect, useState } from "react";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

import CommentsFromClients from "../commentsFromClients";

import { TITLE_TEXTS_OPTIONS, TOTAL_STEPS, UPDATE_INTERVAL } from "./constants";
import { ProcessFindResultProps } from "./types";
import "./styles.scss";

const ProcessFindResult = ({ nextStep }: ProcessFindResultProps) => {
  const [value, setValue] = useState(0);
  const [textIndex, setTextIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setValue((prevValue) => {
        if (prevValue >= 1) {
          clearInterval(interval);
          return 1;
        }
        return prevValue + 1 / TOTAL_STEPS;
      });
    }, UPDATE_INTERVAL);

    return () =>
      clearInterval(interval);
  }, []);

  useEffect(() => {
    const textInterval = setInterval(() => {
      setTextIndex((prevIndex) => {
        if (prevIndex >= TITLE_TEXTS_OPTIONS.length - 1) {
          clearInterval(textInterval);
          return prevIndex;
        }
        return prevIndex + 1;
      });
    }, 2000); // Меняем заголовок каждые 2 секунды

    return () =>
      clearInterval(textInterval);
  }, []);

  useEffect(() => {
    if (value === 1) {
      setTimeout(() => {
        nextStep(4); // Переход к следующему шагу после завершения
      }, 1000);
    }
  }, [value, nextStep]);

  return (
    <div className="process-find-result">
      <p className="process-find-result__title">
        {TITLE_TEXTS_OPTIONS[textIndex]}
      </p>
      <div className="process-find-result__progressbar-block">
        <div className="progressbar-background" />
        <CircularProgressbar
          value={value}
          maxValue={1}
          text={`${Math.round(value * 100)}%`}
          styles={buildStyles({
            textSize: "22px",
            pathColor: "#A656D0",
            textColor: "#000000",
            trailColor: "#E2C2F4",
            backgroundColor: "transparent",
          })}
        />
      </div>
      <p className="process-find-result__info-text">Анализируем ваши ответы...</p>
      <CommentsFromClients />
    </div>
  );
};

export default ProcessFindResult;
