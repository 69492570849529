import React, {
  useCallback, useContext, useEffect, useState,
} from "react";

import { FormTypeEnum } from "@layout/modals/types";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";

import { Button } from "@/components/common/button";
import Modal from "@/components/common/modal";
import MultipleOptionsToggle from "@/components/common/multipleOptionsToggle";
import { CATALOG_PAGE_NAME, UrlParamKeyEnum } from "@/components/constants";
import ReferralKeyErrorModal from "@/components/modals/referralKeyErrorModal";
import SupportModal from "@/components/modals/supportModal";
import WelcomeModal from "@/components/modals/welcomeModal";
import AuthorizationFormContext from "@/contexts/AuthorizationForm/AuthorizationFormContext";

import { getReferralKeyFromCookies } from "../../Template/utils";

import FormWithCodeModal from "./formWithCodeModal";
import LoginByEmailModal from "./loginByEmailModal";
import LoginByPhoneModal from "./loginByPhoneModal";
import OldFormModal from "./oldFormModal";

import "../styles.scss";
import "./styles.scss";

const AuthorizationModals = () => {
  const {
    formType,
    setFormType,
  } = useContext(AuthorizationFormContext);

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [byPhone, setByPhone] = useState(true);

  const setPhoneCallback = useCallback((value: string) => {
    setPhone(value);
  }, []);

  const setEmailCallback = useCallback((value: string) => {
    setEmail(value);
  }, []);

  const { search } = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    let currentFormType = queryParams.get(UrlParamKeyEnum.LoginFormModal);

    if (currentFormType) {
      const parts = currentFormType.split("/");
      currentFormType = parts[0] || null;
    }

    const isCurrentTypeValid = Object.values(FormTypeEnum)
      .includes(currentFormType as FormTypeEnum);

    if (currentFormType && isCurrentTypeValid) {
      setFormType(currentFormType as FormTypeEnum);
    }

    if (!queryParams.has(UrlParamKeyEnum.LoginFormModal)) {
      setFormType(null);
    }
  }, [search, setFormType]);

  switch (formType) {
    case FormTypeEnum.Login:
    case FormTypeEnum.LoginBeforeCall:
    case FormTypeEnum.LoginOnAuthorizedOnlyPage:

      return (
        <Modal
          urlParamKey={UrlParamKeyEnum.LoginFormModal}
          header={formType === FormTypeEnum.LoginBeforeCall ? "Заказать консультацию" : "Войдите или создайте аккаунт"}
          invitation={!!getReferralKeyFromCookies()}
          showLogo
          isBackButtonShown={formType === FormTypeEnum.LoginOnAuthorizedOnlyPage}
          isCloseForbidden={formType === FormTypeEnum.LoginOnAuthorizedOnlyPage}
        >
          <div className="authentication">
            <MultipleOptionsToggle
              options={[
                { id: "0", label: "Почта", isActive: !byPhone },
                { id: "1", label: "Телефон", isActive: byPhone },
              ]}
              onChange={(value) =>
                setByPhone(value === "1")}
            />
            {!byPhone && <LoginByEmailModal setPrevValueCallback={setEmailCallback} />}
            {byPhone && <LoginByPhoneModal setPrevValueCallback={setPhoneCallback} />}
          </div>
        </Modal>
      );
    case FormTypeEnum.LoginAsExpert:
      return (
        <Modal urlParamKey={UrlParamKeyEnum.LoginFormModal} header="Вход для экспертов" isBackButtonShown>
          <OldFormModal setPrevValueCallback={setEmailCallback} />
        </Modal>
      );
    case FormTypeEnum.CodeFromSMS:
      return (
        <Modal urlParamKey={UrlParamKeyEnum.LoginFormModal} header="Код из СМС" isBackButtonShown>
          <FormWithCodeModal contactValue={phone} />
        </Modal>
      );
    case FormTypeEnum.CodeFromMail:
      return (
        <Modal urlParamKey={UrlParamKeyEnum.LoginFormModal} header="Код из письма" isBackButtonShown>
          <FormWithCodeModal contactValue={email} />
        </Modal>
      );
    case FormTypeEnum.Troubles:
      return (
        <SupportModal urlParamKey={UrlParamKeyEnum.LoginFormModal} />
      );
    case FormTypeEnum.Welcome:
      return (
        <Modal
          urlParamKey={UrlParamKeyEnum.LoginFormModal}
          isCentered
          bottomContent={(
            <Button
              text="Выбрать эксперта"
              onClick={() =>
                navigate(`/${CATALOG_PAGE_NAME}`)}
            />
          )}
        >
          <WelcomeModal />
        </Modal>
      );

    case FormTypeEnum.ReferralKeyError:
      return (
        <Modal
          urlParamKey={UrlParamKeyEnum.LoginFormModal}
          isCentered
          bottomContent={(
            <Button
              text="На главную"
              onClick={() =>
                navigate(`/`)}
            />
          )}
        >
          <ReferralKeyErrorModal />
        </Modal>
      );
    default:
      return null;
  }
};

export default AuthorizationModals;
