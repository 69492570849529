import React from "react";

import MenuItem from "@layout/header/Menu/MenuItem";

import "./styles.scss";
import { MENU_DATA } from "./constants";
import { MenuProps } from "./types";

export const Menu = ({ hidePopUpMenu }: MenuProps) =>
  (
    <div className="menu">
      {MENU_DATA.map((item) =>
        <MenuItem key={item.title} item={item} hidePopUpMenu={hidePopUpMenu} />)}
    </div>
  );
