import React, { useContext, useEffect, useState } from "react";

import { useMutation } from "@apollo/client";
import { FormTypeEnum } from "@layout/modals/types";
import { setAuthParamToURL } from "@layout/modals/utils";
import { useLocation } from "@reach/router";

import Modal from "@/components/common/modal";
import { UrlParamKeyEnum } from "@/components/constants";
import AuthorizationFormContext from "@/contexts/AuthorizationForm/AuthorizationFormContext";
import UserContext from "@/contexts/User/UserContext";
import { CONFIRM_EMAIL } from "@components/profile/account/graphql/CONFIRM_EMAIL";

import EmailConfirmation from "./emailConfirmationModal";

import "../styles.scss";

const EmailConfirmationModals = () => {
  const { formType } = useContext(AuthorizationFormContext);
  const { isUserLoggedIn } = useContext(UserContext);
  const location = useLocation();
  const [isAlreadyRedirected, setIsAlreadyRedirected] = useState(false);

  const [
    confirmEmail,
    { data: confirmEmailAnswer, loading: confirmEmailLoading, error: confirmEmailError },
  ] = useMutation(CONFIRM_EMAIL);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const magicData = queryParams.get(UrlParamKeyEnum.EmailConfirmation);
    const currentFormType = queryParams.get(UrlParamKeyEnum.LoginFormModal);

    // ignore logic if authorization form showing or if param not provide to the URL
    if (currentFormType || !magicData) {
      return;
    }

    if (isUserLoggedIn) {
      if (!confirmEmailError && !confirmEmailLoading) {
        confirmEmail({
          variables: {
            magicData,
            confirmationCode: "",
          },
        });
      }
    } else {
      setAuthParamToURL(location, FormTypeEnum.Login);
    }
  }, [isUserLoggedIn, confirmEmail, location, confirmEmailError, confirmEmailLoading]);

  useEffect(() => {
    if (isAlreadyRedirected) {
      return;
    }

    const queryParams = new URLSearchParams(location.search);
    queryParams.delete(UrlParamKeyEnum.EmailConfirmation);
    if (confirmEmailAnswer && !confirmEmailLoading && !confirmEmailError) {
      location.search = queryParams.toString();
      setAuthParamToURL(location, FormTypeEnum.EmailConfirmationSuccess);
      setIsAlreadyRedirected(true);
    }

    if (confirmEmailError) {
      location.search = queryParams.toString();
      setAuthParamToURL(location, FormTypeEnum.EmailConfirmationFailed);
      setIsAlreadyRedirected(true);
    }
  }, [confirmEmailAnswer, confirmEmailLoading, confirmEmailError, location, isAlreadyRedirected]);

  switch (formType) {
    case FormTypeEnum.EmailConfirmationSuccess:
      return (
        <Modal urlParamKey={UrlParamKeyEnum.LoginFormModal}>
          <EmailConfirmation isSuccess />
        </Modal>
      );
    case FormTypeEnum.EmailConfirmationFailed:
      return (
        <Modal urlParamKey={UrlParamKeyEnum.LoginFormModal}>
          <EmailConfirmation isSuccess={false} />
        </Modal>
      );
    default:
      return null;
  }
};

export default EmailConfirmationModals;
