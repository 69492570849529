import React, { useState } from "react";

import moment from "moment/moment";
import { IMask, IMaskInput } from "react-imask";

import { BirthDateProps, InputErrorMessagesEnum } from "@components/common/Inputs/BirthDate/types";
import { getStringDateError } from "@components/common/Inputs/BirthDate/utils";

import "./styles.scss";

const BirthDate = ({
  id,
  initialValue,
  minDateArea = new Date(1900, 0, 1),
  maxDateArea = new Date(),
  isUnderageAllowed = false,
  unnecessaryTextOn = false,
  setDateCallback,
  enterPressCallback,
}: BirthDateProps) => {
  const [birthDate, setBirthDate] = useState<string>(initialValue);
  const momentFormat = "DD.MM.YYYY";

  const dateChangeHandler = (date: string) => {
    const inputError = getStringDateError(date, isUnderageAllowed);
    if (inputError) {
      setDateCallback("", inputError);
    } else {
      setDateCallback(date, inputError);
    }
  };

  const onBlurHandler = () => {
    const inputError = getStringDateError(birthDate, isUnderageAllowed);

    if (inputError === InputErrorMessagesEnum.InvalidFormat) {
      setBirthDate("");
      dateChangeHandler("");
    }
  };

  const onKeyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      event.preventDefault();

      const inputError = getStringDateError(birthDate, isUnderageAllowed);

      if (inputError === InputErrorMessagesEnum.InvalidFormat) {
        setBirthDate("");
        dateChangeHandler("");
      }

      if (!inputError && enterPressCallback) {
        enterPressCallback();
      }
    }
  };

  return (
    <>
    {unnecessaryTextOn && (
      <div className="birth-date__unnecessary-input" />
    )}
    <IMaskInput
      mask={Date}
      pattern={momentFormat}
      type="text"
      value={birthDate}
      onAccept={(value) => {
        // todo: validate invalid dates like 31.02
        setBirthDate(value as string);
        dateChangeHandler(value as string);
      }}
      overwrite
      unmask={false}
      lazy={false}
      id={id}
      min={minDateArea}
      max={maxDateArea}
      format={(date) =>
        moment(date).format(momentFormat)}
      parse={(str) =>
        moment(str, momentFormat)}
      onBlur={onBlurHandler}
      onKeyDown={onKeyDownHandler}
      blocks={{
        YYYY: {
          mask: IMask.MaskedRange,
          from: minDateArea.getFullYear(),
          to: maxDateArea.getFullYear(),
        },
        MM: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 12,
        },
        DD: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 31,
        },
      }}
        // input props also available
      placeholder="__.__.____"
    />
    </>
  );
};

export default BirthDate;
