import React, { useContext, useEffect, useState } from "react";

import { MenuPopUp } from "@layout/header/MenuMobile/MenuPopUp";
import { FormTypeEnum } from "@layout/modals/types";
import { setAuthParamToURL } from "@layout/modals/utils";
import { useLocation } from "@reach/router";
import { Link } from "gatsby";

import { CATALOG_PAGE_NAME } from "@/components/constants";
import UserContext from "@/contexts/User/UserContext";
import { Icon, IconTypeEnum } from "@components/common/icon";

import { MenuMobileProps } from "./types";

import "./styles.scss";

const MenuMobile = ({ redirectMobileProfile, setRedirectMobileProfile }: MenuMobileProps) => {
  const [menuItem, setMenuItem] = useState("");
  const [showMenuPopUp, setShowMenuPopUp] = useState(false);
  const { isUserLoggedIn } = useContext(UserContext);
  const location = useLocation();

  const onAuthorizationLinkClick = () => {
    setAuthParamToURL(location, FormTypeEnum.Login);
  };

  const hidePopUpMenu = () => {
    setShowMenuPopUp(false);
  };

  function changeActivePopUp(target: string) {
    if (menuItem === target && showMenuPopUp) {
      setShowMenuPopUp(false);
      return;
    }

    setMenuItem(target);
    setRedirectMobileProfile("");
    setShowMenuPopUp(true);
  }

  function isMenuItemActive(targetName: string) {
    return showMenuPopUp && menuItem === targetName;
  }

  useEffect(() => {
    if (redirectMobileProfile) {
      changeActivePopUp(redirectMobileProfile);
    }
  }, [redirectMobileProfile]);

  return (
    <>
      <div className="menu-mobile">
        <div
          className={`menu-mobile__item ${isMenuItemActive("menu") ? "active" : ""}`}
          onMouseDown={() =>
            changeActivePopUp("menu")}
        >
          <Icon type={IconTypeEnum.Menu} />
          Меню
        </div>
        <Link
          to="/"
          onMouseDown={hidePopUpMenu}
          className="menu-mobile__item"
          activeClassName={`${showMenuPopUp ? "" : "active"}`}
        >
          <Icon type={IconTypeEnum.Home} />
          Главная
        </Link>
        <Link
          to={`/${CATALOG_PAGE_NAME}`}
          onMouseDown={hidePopUpMenu}
          className="menu-mobile__item"
          activeClassName={`${showMenuPopUp ? "" : "active"}`}
        >
          <Icon type={IconTypeEnum.Category} />
          Эксперты
        </Link>
        {isUserLoggedIn ? (
          <div
            className={`menu-mobile__item ${isMenuItemActive("profile") ? "active" : ""}`}
            onMouseDown={() =>
              changeActivePopUp("profile")}
          >
            <Icon type={IconTypeEnum.Profile} />
            Аккаунт
          </div>
        ) : (
          <div className="menu-mobile__item" onMouseDown={onAuthorizationLinkClick}>
            <Icon type={IconTypeEnum.Profile} />
            Войти
          </div>
        )}
      </div>
      <MenuPopUp menuItem={menuItem} showMenuPopUp={showMenuPopUp} hidePopUpMenu={hidePopUpMenu} />
    </>
  );
};

export default MenuMobile;
