export enum ChangePhoneModalEnum {
  Confirm = "confirm_phone",
  Support = "support"
}

export type ChangePhoneModalsProps = {
  phone: string;
  successCallback?: () => void;
  closeCallback: () => void;
  backClickCallback: () => void;
}
