import { IconTypeEnum } from "@/components/common/icon";

import commentsImg1 from "./components/commentsFromClients/images/img-1.png";
import commentsImg2 from "./components/commentsFromClients/images/img-2.png";
import commentsImg3 from "./components/commentsFromClients/images/img-3.png";
import commentsImg4 from "./components/commentsFromClients/images/img-4.png";
import commentsImg5 from "./components/commentsFromClients/images/img-5.png";
import commentsImg6 from "./components/commentsFromClients/images/img-6.png";
import { PopularQuestionsData } from "./types";

export const questions = {
  step1: [
    {
      icon: IconTypeEnum.Smile_1,
      text: "Спокойно",
    },
    {
      icon: IconTypeEnum.Smile_2,
      text: "Тревожно",
    },
    {
      icon: IconTypeEnum.Smile_3,
      text: "Растерянно",
    },
    {
      icon: IconTypeEnum.Smile_4,
      text: "Грустно",
    },
    {
      icon: IconTypeEnum.Smile_5,
      text: "Взволнованно",
    },
    {
      icon: IconTypeEnum.Smile_6,
      text: "Раздражённо",
    },
  ],
  step2: [
    {
      icon: IconTypeEnum.Topic_icon_7,
      text: "Деньги \nи благосостояние",
    },
    {
      icon: IconTypeEnum.Topic_icon_4,
      text: `Карьерные \nперспективы`,
    },
    {
      icon: IconTypeEnum.Smile_4,
      text: "Проблемы с долгами \nи кредитами",
    },
    {
      icon: IconTypeEnum.Topic_icon_6,
      text: "Другой\nвопрос",
    },
  ],
};

export const popularQuestionsData: PopularQuestionsData = {
  questions_1: {
    categoryName: "Деньги и благосостояние",
    questions: [
      { id: 1, question: "Мои перспективы в финансах в ближайшее время?" },
      { id: 2, question: "В какой сфере я получу признание и доход?" },
      { id: 3, question: "Как мне «пробить потолок» в финансах?" },
      { id: 4, question: "Как улучшить моё финансовое положение в ближайшие месяцы?" },
      { id: 5, question: `Что мне мешает много зарабатывать?` },
      { id: 6, question: "Где мои деньги? Ваш источник финансового благополучия" },
      { id: 6, question: "Задам свой вопрос" },
    ],
  },
  questions_2: {
    categoryName: "Карьерные перспективы",
    questions: [
      { id: 1, question: "В какой сфере я получу признание и доход?" },
      { id: 2, question: "Мои перспективы в карьере на текущем месте работы?" },
      { id: 3, question: "Как продвинуться по карьерной лестнице?" },
      { id: 4, question: "Стоит ли менять работу?" },
      { id: 5, question: `Что сделать, чтобы повысили зарплату?` },
      { id: 6, question: `Как подготовиться к собеседованию, чтобы предложили работу?` },
      { id: 7, question: "Задам свой вопрос" },
    ],
  },
  questions_3: {
    categoryName: "Проблемы с долгами и кредитами",
    questions: [
      { id: 1, question: "Как избавиться от кредитов?" },
      { id: 2, question: "Почему меня преследуют кредиты и долги?" },
      { id: 3, question: "Почему не получается копить и стабильно зарабатывать?" },
      { id: 4, question: "Как мне улучшить свою финансовую ситуацию и избежать долгов в будущем?" },
      { id: 5, question: "Что мне делать, чтобы погасить кредит как можно быстрее?" },
      { id: 6, question: "Как заработать больше, чтобы погасить кредиты?" },
      { id: 7, question: "Задам свой вопрос" },
    ],
  },
  questions_4: {
    categoryName: "Другой вопрос",
    questions: [
      { id: 1, question: "Когда мне стоит взять отпуск и как его провести?" },
      { id: 2, question: "Исполнится ли моё желание?" },
      { id: 3, question: "Что мне сделать, чтобы чувствовать себя лучше?" },
      { id: 4, question: "Что меня ждёт в ближайший месяц?" },
      { id: 5, question: "Как мне благоприятнее действовать в моей ситуации?" },
      { id: 6, question: "Задам свой вопрос" },
    ],
  },
};

export const commentsFromClientsData = [
  {
    id: 1,
    img: commentsImg1,
    clientName: "Дарья Олеговна",
    rate: 5,
    date: "20 апр 2024",
    comment: "С профессиональными и рабочими вопросами только к экспертам Лунаро). Очень точные и четкие расклады, всегда четкие советы на уровне. Подсказали, чего нужно остерегаться. Расклады на развитие событий 100 из 100. Лилия увидела несколько месяцев назад событие, о котором я и не предполагала, но теперь я знаю, о каком событии идет речь (на работе сказали). Есть понимание, как себя вести и к чему готовиться. Консультация, как всегда, огонь.",
  },
  {
    id: 2,
    img: commentsImg2,
    clientName: "Анастасия УДАЧА",
    rate: 5,
    date: "6 мая 2024",
    comment: "Спрашивала про сферу бизнеса, в принципе мои догадки оправдались и выявили перспективную идею, над которой сейчас нужно сфокусироваться. Будто с мыслях порядок появился, благодарю от души",
  },
  {
    id: 3,
    img: commentsImg3,
    clientName: "Екатерина",
    rate: 5,
    date: "4 апр 2024",
    comment: "Огромная благодарность Ане! Сильный профессионал! За 30 мин мы посмотрели три конкретные ситуации по бизнесу. Я получила ответы на все вопросы, плюс еще Аня мне подсветила моменты, на которые стоит обратить внимание. Рекомендую!!!!",
  },
  {
    id: 4,
    img: commentsImg4,
    clientName: "Виктория",
    rate: 5,
    date: "20 фев 2024",
    comment: "Благодарю за предоставленную возможность пообщаться с экспертом в области таро и психологии, Лита Вэйн помогла разобраться в моей ситуации, благодаря ей задала себе правильное нужное направление для себя, определила сферу деятельности, чем хочу заниматься в данный момент, очень приветливая,добрая девушка Лита ⭐️ чувствуется поддержка, профессионализм и вера в своих клиентов! Договорились продолжить нашу встречу, продлили сессию на 21минуту. Благодарю за вашу заботу",
  },
  {
    id: 5,
    img: commentsImg5,
    clientName: "Имя скрыто",
    rate: 5,
    date: "20 фев 2024",
    comment: "Хочу выразить огромную Благодарность Леи. За короткую консультацию она мне дала ответы на все мои вопросы. Мне нужен был срочный совет ответ о продаже. Я его получила. Благодарю.",
  },
  {
    id: 6,
    img: commentsImg6,
    clientName: "Inna",
    rate: 5,
    date: "20 фев 2024",
    comment: `Про портал "Lunaro.ru" узнала случайно, мне стало интересно пообщаться с тарологом экспертом и сделать раскладку по сфере "самореализации" и своего состояния, в этом мне помогла специалист Лита Вэйн. Очень приятная девушка, профессионал своего дела, очень деликатно, популярным языком всё объяснила, консультация прошла на одном дыхании,очень плодотворно. Помогла разобраться в моих вопросах,выяснить причину возникновения моего выгорания, дала нужные рекомендации как решить свои проблемы, определили направления, в чём у меня интерес, в чём можно развиваться, учитывая мои обстоятельства. С радостью буду рекомендовать всем своим знакомым Литу и данный портал. Благодарю администраторов Настю и Элину за помощь и поддержку, что всё объясняли, отвечали на все вопросы, чувствуется забота и любовь ❤️`,
  },
];
