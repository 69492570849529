import React from "react";

import logo from "@images/authorization/logo.svg";
import { setAuthParamToURL } from "@layout/modals/utils";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";

import { isBrowser } from "@/utils/env";
import { Icon, IconSizeEnum, IconTypeEnum } from "@components/common/icon";

import { ModalProps } from "./types";

import "./styles.scss";

const Modal = ({
  urlParamKey,
  header,
  invitation,
  subtitle,
  isBackButtonShown = false,
  children = null,
  className = "",
  showLogo = false,
  isCentered = false,
  bottomContent,
  formCloseCallback,
  isCloseForbidden = false,
}: ModalProps) => {
  const isShown = true; // todo: make appearance animation with CSS and timer
  const location = useLocation();

  const closeModal = () => {
    if (formCloseCallback) {
      formCloseCallback();
    }
    setAuthParamToURL(location, null, urlParamKey);
  };

  const backHandler = () => {
    if (isBrowser()) {
      navigate(-1);
    }
  };

  return (
    <div
      className={`modal__container${isShown ? " is-opened" : ""} ${className}`}
      onClick={() => {
        if (!isCloseForbidden) closeModal();
      }}
    >
      <div className="modal">
        <div className="modal__inner">
          <div
            className={`modal__form${isCentered ? " modal__form--centered" : ""}`}
            onClick={(e) =>
              e.stopPropagation()}
          >
            <div className="modal__top">
              {showLogo && <img src={logo} alt="Логотип Lunaro" />}
              {invitation && <div className="modal__invitation">Друг приглашает вас на Lunaro ❤️</div> }
              {header && <h2 className={`modal__header ${invitation ? "whith-invitation" : ""}`}>{header}</h2>}
              {" "}
              {subtitle && <div className="modal__subtitle">{subtitle}</div>}
              {!isCloseForbidden && (
                <Icon
                  className="modal__close"
                  onClick={closeModal}
                  type={IconTypeEnum.Close}
                  size={IconSizeEnum.Size32}
                />
              )}
              {isBackButtonShown
                && (
                  <Icon
                    className="modal__back"
                    onClick={backHandler}
                    type={IconTypeEnum.Left}
                    size={IconSizeEnum.Size32}
                  />
                )}
            </div>
            <div className="modal__content">{children}</div>
            <div className="modal__bottom">{bottomContent}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
