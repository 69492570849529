import React, { useContext } from "react";

import { Button, ButtonIconPositionEnum } from "@/components/common/button";
import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";
import { UserAvatar } from "@/components/common/userAvatar";
import { UserAvatarTypeEnum } from "@/components/common/userAvatar/types";
import UserInfo from "@/components/common/userInfo";
import GlobalContext from "@/contexts/Global/GlobalContext";

import { InProgressAlertProps } from "./types";

import "../../styles.scss";
import "./styles.scss";

const InProgressAlert = ({ receiver }: InProgressAlertProps) => {
  const { setIsAlertShown } = useContext(GlobalContext);

  const clearAlert = () => {
    setIsAlertShown(false);
  };

  return (
    <>
      <div className="modal__top" />
      <div
        className="modal__content alert__modal alert_in_progress"
      >
        {receiver && (
          <UserInfo
            avatar={(
              <UserAvatar
                mobileImage={receiver.mobileImage}
                desktopImage={receiver.desktopImage}
                name={receiver.name}
                userId={receiver.id}
                type={UserAvatarTypeEnum.Call}
              />
            )}
          >
            <div className="alert__modal-icon alert_in_progress__modal-icon">
              <Icon type={IconTypeEnum.CallIncoming} size={IconSizeEnum.Size48} />
            </div>
          </UserInfo>
        )}
        <h2 className="h2">
          Консультация уже идёт
          <br />
          на другой вкладке
        </h2>
        <div className="alert__modal-buttons">
          <div className="alert__modal-button">
            <Button
              text="Закрыть"
              icon={IconTypeEnum.Reload}
              iconPosition={ButtonIconPositionEnum.Left}
              onClick={clearAlert}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default InProgressAlert;
