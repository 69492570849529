import React, { useEffect, useState } from "react";

import { useLocation } from "@reach/router";
import Modal from "@/components/common/modal";
import { UrlParamKeyEnum } from "@/components/constants";
import ChangePhone from "@/components/common/changePhone";
import SupportModal from "@/components/modals/supportModal";
import { setAuthParamToURL } from "@/components/layout/modals/utils";

import { ChangePhoneModalEnum, ChangePhoneModalsProps } from "./types";

const ChangePhoneModal = ({ phone, successCallback, closeCallback, backClickCallback }: ChangePhoneModalsProps) => {
  const { search } = useLocation();
  const [
    formType,
    setFormType,
  ] = useState<ChangePhoneModalEnum | null>(null);

  useEffect(() => {
    setAuthParamToURL(window.location, ChangePhoneModalEnum.Confirm, UrlParamKeyEnum.AccountModal);
  }, []);

  const helpClickCallback = () => {
    setAuthParamToURL(window.location, ChangePhoneModalEnum.Support, UrlParamKeyEnum.AccountModal);
  };

  // todo: maybe move this logic to util and reuse in authorization modals logic
  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const currentFormType = queryParams.get(UrlParamKeyEnum.AccountModal);
    const isCurrentTypeValid = Object.values(ChangePhoneModalEnum)
      .includes(currentFormType as ChangePhoneModalEnum);
    if (currentFormType && isCurrentTypeValid) {
      setFormType(currentFormType as ChangePhoneModalEnum);
    } else if (!currentFormType) {
      setFormType(null);
    }
  }, [search, setFormType]);
  
  switch (formType) {
    case ChangePhoneModalEnum.Confirm:
      return (
        <Modal
          urlParamKey={UrlParamKeyEnum.AccountModal}
          header="Код из СМС"
          subtitle="Введите код, чтобы привязать номер телефона"
          isBackButtonShown
          formCloseCallback={closeCallback}
        >
          <ChangePhone
            phone={phone}
            successCallback={successCallback}
            helpClickCallback={helpClickCallback}
            backClickCallback={backClickCallback}
          />
        </Modal>
      );
    case ChangePhoneModalEnum.Support:
      return <SupportModal urlParamKey={UrlParamKeyEnum.AccountModal} />;
    default: return null;
  }
};

export default ChangePhoneModal;
