import React from "react";

import FinalModalContent from "../finalModalContent";
import PopularQuestions from "../popularQuestions";
import ProcessFindResult from "../processFindResult";
import QuestionTopic from "../questionTopic";

import { StepContentProps } from "./types";

const StepContent: React.FC<StepContentProps> = ({
  step,
  nextStep,
  setCheckedThemeId,
  checkedThemeId,
  marketingOnboardingAnswersHandler,
}) => {
  switch (step) {
    case 1:
      return (
        <QuestionTopic
          nextStep={nextStep}
          setCheckedThemeId={setCheckedThemeId}
          marketingOnboardingAnswersHandler={marketingOnboardingAnswersHandler}
        />
      );
    case 2:
      return (
        <PopularQuestions
          nextStep={nextStep}
          checkedThemeId={checkedThemeId}
          marketingOnboardingAnswersHandler={marketingOnboardingAnswersHandler}
        />
      );
    case 3:
      return <ProcessFindResult nextStep={nextStep} />;
    case 4:
      return (
        <FinalModalContent
          marketingOnboardingAnswersHandler={marketingOnboardingAnswersHandler}
        />
      );
    default:
      return <div>Неизвестный шаг</div>;
  }
};

export default StepContent;
