import React, { useCallback } from "react";

import { navigate } from "gatsby";
import PhoneBlock from "@/components/profile/account/loginTypeBlocks/phoneBlock";
import { setAuthParamToURL } from "@/components/layout/modals/utils";
import { getRedirectUrlSessionStorage, setRedirectUrlSessionStorage } from "@/account/redirect";

const PhoneModal = () => {
  const successHandler = useCallback(() => {
    const redirectUrl = getRedirectUrlSessionStorage() || "";

    if (redirectUrl) {
      navigate(redirectUrl);
      setRedirectUrlSessionStorage("");
    } else {
      setAuthParamToURL(window.location, null);
    }
  }, []);

  return (
    <PhoneBlock successHandler={successHandler} />
  );
}

export default PhoneModal;
