import { ErrorCodeEnum } from "@layout/modals/types";
import { navigate } from "gatsby";

import { UrlParamKeyEnum } from "@/components/constants";
import { get2Decimals } from "@/utils/numberUtils";

export function convertToI18lClearPhone(notFormattedPhone: string) {
  return notFormattedPhone.replace(/[^\d.+]/g, "");
}

/**
 * Converts date from 08.01.1999 to 1999-01-08
 * @param date
 * @returns
 */
export function convertDateForBack(date: string | null) {
  if (!date) {
    return "";
  }

  return date.split(".").map((element) =>
    get2Decimals(element)).reverse().join("-");
}

/**
 * Converts date from 1999-01-08 to 08.01.1999
 * @param date
 * @returns
 */
export function convertDateForFront(date: string | null) {
  if (!date) {
    return "";
  }

  return date.split("-").map((element) =>
    get2Decimals(element)).reverse().join(".");
}

/**
 * Sets or clears modal type to URL
 * @param location Window location
 * @param formType Type of modal to set or null to clear it
 */
export function setAuthParamToURL(
  location: Location,
  formType: string | null,
  urlParamKey: UrlParamKeyEnum = UrlParamKeyEnum.LoginFormModal,
  replaceUrlFlag: boolean = false,
) {
  const { pathname, search } = location;
  const queryParams = new URLSearchParams(search);
  const urlValue = queryParams.get(urlParamKey);

  if (formType === urlValue) {
    return;
  }

  if (!formType) {
    queryParams.delete(urlParamKey);
  } else if (!queryParams.has(urlParamKey)) {
    queryParams.append(urlParamKey, formType);
  } else if (urlValue !== formType) {
    queryParams.set(urlParamKey, formType);
  }

  const separator = queryParams.toString().length ? "?" : "";
  navigate(`${pathname}${separator}${queryParams}`, { replace: replaceUrlFlag });
}

export function getErrorByCode(code: number, additionalText = "письмо") {
  switch (code) {
    case ErrorCodeEnum.StatusCodeInternalError:
      return `Что-то пошло не так, попробуйте еще раз`;
    case ErrorCodeEnum.StatusCodeBadRequest:
      return `Переданы некорректные данные`;
    case ErrorCodeEnum.StatusCodeNoSuchAccount:
      return `Аккаунта с такими данными не найдено`;
    case ErrorCodeEnum.StatusCodeWrongCredentials:
      return `Неверный код, проверьте ${additionalText} или получите новый`;
    case ErrorCodeEnum.StatusCodeAuthMethodNotSupported:
      return `Данный метод авторизации не поддерживается`;
    case ErrorCodeEnum.StatusCodeInvalidPhone:
      return `Некорректно введен номер телефона`;
    case ErrorCodeEnum.StatusCodeEmailNotVerified:
      return `Ваш e-mail не подтверждён. Авторизуйтесь по номеру телефона и подтвердите e-mail в Личном кабинете`;
    case ErrorCodeEnum.StatusCodeTooManyRequests:
      return `Слишком много запросов, попробуйте позже`;
    case ErrorCodeEnum.StatusCodeEmailAlreadyUsed:
      return `Аккаунт с таким e-mail уже есть, попробуйте ввести другой`;
    case ErrorCodeEnum.StatusCodePhoneNotSupported:
      return `Данный номер телефона не поддерживается`;
    case ErrorCodeEnum.StatusCodeBlockedUsers:
      return `Аккаунт заблокирован за нарушение Правил Сервиса. Обратитесь в Службу поддержки`;
    case ErrorCodeEnum.StatusCodeReCaptchaError:
    case ErrorCodeEnum.errMaxCountOfUsersWithSuchIpExceeded:
      return `Очень много автоматических запросов. \n Сайт подумал, что вы робот, но мы верим, что это не так. \n Напишите нам в Службу поддержки, мы поможем восстановить доступ`;
    default:
      return null;
  }
}
