import React from "react";

import { Helmet } from "react-helmet";

import type { ModalWrapperProps } from "./types";
import "../styles.scss";

const ModalWrapper = ({ children }: ModalWrapperProps) =>
  (
    <>
      <Helmet>
        <html className="overflow--hidden" lang="ru" />
      </Helmet>

      <div className="modal__container is-opened">
        <div className="modal">
          <div className="modal__inner">
            <div className="modal__form">
              {children}
              <div className="modal__bottom" />
            </div>
          </div>
        </div>
      </div>
    </>
  );

export default ModalWrapper;
