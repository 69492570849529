import React, { ReactElement } from "react";

import ConditionalWrapper from "@components/ConditionalWrapper";

type FooterColumnType = {
  title?: string;
  children?: ReactElement;
  className?: string;
  wrapperClass?: string;
  [x: string]: any;
};

const FooterColumn = (props: FooterColumnType) => {
  const {
    title, children, wrapperClass = "", className = "", ...rest
  } = props;
  return (
    <div className={`footer__column ${className}`} {...rest}>
      <ConditionalWrapper
        condition={!!wrapperClass}
        wrapper={(children) =>
          <div className={wrapperClass}>{children}</div>}
      >
        <>
          {title && <h4 className="h4">{title}</h4>}
          {children}
        </>
      </ConditionalWrapper>
    </div>
  );
};

export default FooterColumn;
