exports.components = {
  "component---src-components-catalog-catalog-container-index-tsx": () => import("./../../../src/components/catalog/catalogContainer/index.tsx" /* webpackChunkName: "component---src-components-catalog-catalog-container-index-tsx" */),
  "component---src-components-expert-index-tsx": () => import("./../../../src/components/expert/index.tsx" /* webpackChunkName: "component---src-components-expert-index-tsx" */),
  "component---src-components-text-pages-article-index-tsx": () => import("./../../../src/components/textPages/article/index.tsx" /* webpackChunkName: "component---src-components-text-pages-article-index-tsx" */),
  "component---src-components-text-pages-category-index-tsx": () => import("./../../../src/components/textPages/category/index.tsx" /* webpackChunkName: "component---src-components-text-pages-category-index-tsx" */),
  "component---src-components-text-pages-gid-index-tsx": () => import("./../../../src/components/textPages/gid/index.tsx" /* webpackChunkName: "component---src-components-text-pages-gid-index-tsx" */),
  "component---src-components-text-pages-goroskop-index-tsx": () => import("./../../../src/components/textPages/goroskop/index.tsx" /* webpackChunkName: "component---src-components-text-pages-goroskop-index-tsx" */),
  "component---src-components-text-pages-info-page-index-tsx": () => import("./../../../src/components/textPages/infoPage/index.tsx" /* webpackChunkName: "component---src-components-text-pages-info-page-index-tsx" */),
  "component---src-components-text-pages-landing-index-tsx": () => import("./../../../src/components/textPages/landing/index.tsx" /* webpackChunkName: "component---src-components-text-pages-landing-index-tsx" */),
  "component---src-components-text-pages-promo-index-tsx": () => import("./../../../src/components/textPages/promo/index.tsx" /* webpackChunkName: "component---src-components-text-pages-promo-index-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-appointment-index-tsx": () => import("./../../../src/pages/appointment/index.tsx" /* webpackChunkName: "component---src-pages-appointment-index-tsx" */),
  "component---src-pages-auth-google-index-tsx": () => import("./../../../src/pages/auth/google/index.tsx" /* webpackChunkName: "component---src-pages-auth-google-index-tsx" */),
  "component---src-pages-auth-vk-index-tsx": () => import("./../../../src/pages/auth/vk/index.tsx" /* webpackChunkName: "component---src-pages-auth-vk-index-tsx" */),
  "component---src-pages-authorize-index-tsx": () => import("./../../../src/pages/authorize/index.tsx" /* webpackChunkName: "component---src-pages-authorize-index-tsx" */),
  "component---src-pages-chat-fix-media-access-index-tsx": () => import("./../../../src/pages/chat/fix-media-access/index.tsx" /* webpackChunkName: "component---src-pages-chat-fix-media-access-index-tsx" */),
  "component---src-pages-chat-index-tsx": () => import("./../../../src/pages/chat/index.tsx" /* webpackChunkName: "component---src-pages-chat-index-tsx" */),
  "component---src-pages-chat-review-index-tsx": () => import("./../../../src/pages/chat/review/index.tsx" /* webpackChunkName: "component---src-pages-chat-review-index-tsx" */),
  "component---src-pages-payment-processing-index-tsx": () => import("./../../../src/pages/payment-processing/index.tsx" /* webpackChunkName: "component---src-pages-payment-processing-index-tsx" */),
  "component---src-pages-profile-account-index-tsx": () => import("./../../../src/pages/profile/account/index.tsx" /* webpackChunkName: "component---src-pages-profile-account-index-tsx" */),
  "component---src-pages-profile-balance-index-tsx": () => import("./../../../src/pages/profile/balance/index.tsx" /* webpackChunkName: "component---src-pages-profile-balance-index-tsx" */),
  "component---src-pages-profile-payment-index-tsx": () => import("./../../../src/pages/profile/payment/index.tsx" /* webpackChunkName: "component---src-pages-profile-payment-index-tsx" */),
  "component---src-pages-profile-payment-result-index-tsx": () => import("./../../../src/pages/profile/payment-result/index.tsx" /* webpackChunkName: "component---src-pages-profile-payment-result-index-tsx" */),
  "component---src-pages-profile-promo-codes-index-tsx": () => import("./../../../src/pages/profile/promo-codes/index.tsx" /* webpackChunkName: "component---src-pages-profile-promo-codes-index-tsx" */),
  "component---src-pages-profile-referral-link-index-tsx": () => import("./../../../src/pages/profile/referral-link/index.tsx" /* webpackChunkName: "component---src-pages-profile-referral-link-index-tsx" */),
  "component---src-pages-profile-sessions-index-tsx": () => import("./../../../src/pages/profile/sessions/index.tsx" /* webpackChunkName: "component---src-pages-profile-sessions-index-tsx" */),
  "component---src-pages-session-index-tsx": () => import("./../../../src/pages/session/index.tsx" /* webpackChunkName: "component---src-pages-session-index-tsx" */),
  "component---src-pages-text-chat-index-tsx": () => import("./../../../src/pages/textChat/index.tsx" /* webpackChunkName: "component---src-pages-text-chat-index-tsx" */),
  "component---src-pages-users-deactivate-index-tsx": () => import("./../../../src/pages/users/deactivate/index.tsx" /* webpackChunkName: "component---src-pages-users-deactivate-index-tsx" */),
  "component---src-pages-users-set-new-password-index-tsx": () => import("./../../../src/pages/users/set_new_password/index.tsx" /* webpackChunkName: "component---src-pages-users-set-new-password-index-tsx" */),
  "component---src-pages-video-session-fix-media-access-index-tsx": () => import("./../../../src/pages/videoSession/fix-media-access/index.tsx" /* webpackChunkName: "component---src-pages-video-session-fix-media-access-index-tsx" */),
  "component---src-pages-video-session-index-tsx": () => import("./../../../src/pages/videoSession/index.tsx" /* webpackChunkName: "component---src-pages-video-session-index-tsx" */),
  "component---src-pages-video-session-review-index-tsx": () => import("./../../../src/pages/videoSession/review/index.tsx" /* webpackChunkName: "component---src-pages-video-session-review-index-tsx" */)
}

