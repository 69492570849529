import React, { useState } from "react";

import { Menu } from "@layout/header/Menu";
import MenuMobile from "@layout/header/MenuMobile";
import User from "@layout/header/User";

import LogoBlock from "@/components/common/logoBlock";

import OnboardingBanner from "./OnboardingBanner";
import "./styles.scss";

const Header = () => {
  const [redirectMobileProfile, setRedirectMobileProfile] = useState(""); // todo: probably remake

  return (
    <header className="header">
      <OnboardingBanner/>
      <div className="page-width">
        <div className="header__inner">
          <div className="header__logo">
            <LogoBlock className="header__logo-link" />
          </div>
          <Menu />
          <User setRedirectMobileProfile={setRedirectMobileProfile} />
        </div>
      </div>
      <MenuMobile
        redirectMobileProfile={redirectMobileProfile} // todo: probably remake
        setRedirectMobileProfile={setRedirectMobileProfile} // todo: probably remake
      />
    </header>
  );
};

export default Header;
