import React, { useContext, useEffect } from "react";

import { useLazyQuery } from "@apollo/client";
import {
  getMyProfileForHeader,
  getMyProfileForHeader_getMyProfile,
} from "@layout/header/graphql/__generated__/getMyProfileForHeader";
import {
  GET_MY_PROFILE_FOR_HEADER,
} from "@layout/header/graphql/GET_MY_PROFILE_FOR_HEADER";
import { useLocation } from "@reach/router";
import { Link, navigate } from "gatsby";

import { removeAccessToken, removeRefreshToken } from "@/account/user";
import FreeMinutesDisplay from "@/components/common/freeMinutesDisplay";
import { Tooltip, TooltipPositionEnum } from "@/components/common/tooltip";
import { UserAvatar } from "@/components/common/userAvatar";
import { reviewPagePath } from "@/components/constants";
import GlobalContext from "@/contexts/Global/GlobalContext";
import UserContext from "@/contexts/User/UserContext";
import client from "@/gatsby-plugin-apollo/client";
import { removeCookiesCarrotQuest } from "@/utils/cookieUtils";
import { currencyToString } from "@/utils/globalTypesUtils";
import { priceToString } from "@/utils/numberUtils";
import { Button, ButtonColorEnum, ButtonSizeEnum } from "@components/common/button";
import { Icon, IconSizeEnum, IconTypeEnum } from "@components/common/icon";
import Loader from "@components/common/loader";
import { UserAvatarTypeEnum } from "@components/common/userAvatar/types";
import UserInfo from "@components/common/userInfo";
import { MENU_ITEMS } from "@components/profile/profileLayout/constants";

import { UserMenuProps } from "./types";

const UserMenu = (props: UserMenuProps) => {
  const {
    setBalance,
    unfinishedPaymentId,
  } = useContext(UserContext);
  const {
    profileData,
    hidePopUpMenu,
  } = props;
  const [
    getMyProfile,
    {
      loading: getMyProfileLoading,
      error: getMyProfileError,
      data: getMyProfileData,
    },
  ] = useLazyQuery<getMyProfileForHeader>(GET_MY_PROFILE_FOR_HEADER, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });
  const location = useLocation();
  const redirectFromReviewPage = location.pathname === reviewPagePath;

  const { setIsExpert } = useContext(GlobalContext);
  const profile: getMyProfileForHeader_getMyProfile = getMyProfileData?.getMyProfile ?? profileData;

  const onLogoutLinkClick = () => {
    removeAccessToken();
    removeRefreshToken();
    removeCookiesCarrotQuest();
    const { cache } = client;
    cache.reset();
    navigate("/");
    if (hidePopUpMenu) {
      hidePopUpMenu();
    }
  };

  const handlePayClick = () => {
    if (hidePopUpMenu) {
      hidePopUpMenu();
    }
    navigate("/profile/payment/");
  };

  const handleLinkClick = () => {
    if (hidePopUpMenu) {
      hidePopUpMenu();
    }
  };

  useEffect(() => {
    if (!profileData) {
      getMyProfile();
    }
  }, []);

  useEffect(() => {
    if (!getMyProfileLoading && !getMyProfileError && getMyProfileData) {
      setBalance(getMyProfileData.getMyProfile.balance);
      setIsExpert(getMyProfileData.getMyProfile?.isExpert);
    }
  }, [getMyProfileLoading, getMyProfileError, getMyProfileData]);

  return (
    <div className="user-menu">
      {profile ? (
        <>
          <UserInfo
            avatar={(
              <UserAvatar
                mobileImage={profile.user.mobileImage}
                desktopImage={profile.user.desktopImage}
                name={profile.user.name}
                userId={profile.user.id}
                type={UserAvatarTypeEnum.Header}
              />
            )}
            title={profile.user.name || profile.email || profile.user.id.toString()}
            subtitle={
              !profile.user.name && !profile.email
                ? ""
                : `ID${profile.user.id.toString()}${
                  !!profile.user.name && !!profile.email ? ` • ${profile.email}` : ""
                }`
            }
            className="user-info--small user-menu__item"
          />
          {!profile.isExpert && (
            <>
              <div className="user-menu__item header__balance-container">
                <div className="header__balance">
                  <div className="header__balance-left">
                    На балансе
                    {unfinishedPaymentId ? (
                      <div className="header__balance-loader">
                        <Loader />
                      </div>
                    ) : (
                      <>
                        <div
                          className="h4 header__balance-amount"
                          onClick={() =>
                            navigate("/profile/balance")}
                        >
                          {priceToString(profile.balance.amount)}
                          {" "}
                          {currencyToString(profile.balance.currency)}
                        </div>
                        <div className="header__gift-minutes-block">
                          <FreeMinutesDisplay
                            withMinutes
                            iconSize={IconSizeEnum.Size24}
                            hidePopUpMenu={hidePopUpMenu || undefined}
                          />
                          <Tooltip position={TooltipPositionEnum.Top} text="Бесплатные минуты" isHoverEvent>
                            <p className="header__tooltip-text">Что это?</p>
                          </Tooltip>
                        </div>
                      </>
                    )}
                  </div>
                  <Button
                    text="Пополнить"
                    size={ButtonSizeEnum.XSmall}
                    color={ButtonColorEnum.Black}
                    onClick={handlePayClick}
                  />
                </div>
              </div>
              {MENU_ITEMS.map((item) =>
                (
                  <Link
                    to={item.link}
                    className={`user-menu__item user-menu__link ${item.subTitle ? " user-menu__position-icons-top" : ""}`}
                    activeClassName="active"
                    key={item.link}
                    onClick={handleLinkClick}
                    state={{ showBannerFromFlocktory: !!redirectFromReviewPage }}
                  >
                    <Icon type={item.icon} size={IconSizeEnum.Size24} />
                    <div>
                      <span className="user-menu__text">
                        {item.text}
                        {item.indicator && <div className="user-menu__indicator-link" />}
                      </span>
                      {item.subTitle && (
                      <div className="user-menu__subtitle">
                        {item.subTitle}
                      </div>
                      )}
                    </div>
                  </Link>
                ))}
            </>
          )}

          <hr />
          <div className="user-menu__item user-menu__link" onClick={onLogoutLinkClick}>
            <Icon type={IconTypeEnum.LogOut} size={IconSizeEnum.Size24} />
            Выйти
          </div>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default UserMenu;
