import React, { useMemo, useState } from "react";

import { FormTypeEnum, LoginTypeEnum } from "@layout/modals/types";

import AuthorizationFormContext from "@/contexts/AuthorizationForm/AuthorizationFormContext";

import { AuthorizationFormContextProps } from "./types";

const AuthorizationFormContextProvider = ({ children }: AuthorizationFormContextProps) => {
  const [isShown, setIsShown] = useState<boolean | undefined>(undefined);

  const [formType, setFormType] = useState<FormTypeEnum | null>(null);
  const [loginType, setLoginType] = useState<LoginTypeEnum>(LoginTypeEnum.LoginByPhone);
  const [backButtonFormType, setBackButtonFormType] = useState<FormTypeEnum>(FormTypeEnum.Login);
  const [formCloseCallback, setFormCloseCallback] = useState<(() => void) | null>(null);
  const [sentCodeTimestamp, setSentCodeTimestamp] = useState<number | null>(null);

  const AuthorizationFormContextValue = useMemo(
    () =>
      ({
        backButtonFormType,
        formCloseCallback,
        formType,
        isShown,
        loginType,
        sentCodeTimestamp,
        setBackButtonFormType,
        setFormCloseCallback,
        setFormType,
        setIsShown,
        setLoginType,
        setSentCodeTimestamp,
      }),
    [backButtonFormType, formCloseCallback, formType, isShown, loginType, sentCodeTimestamp],
  );

  return (
    <AuthorizationFormContext.Provider
      value={AuthorizationFormContextValue}
    >
      {children}
    </AuthorizationFormContext.Provider>
  );
};

export default AuthorizationFormContextProvider;
