import React, { useCallback, useContext, useEffect, useState } from "react";

import { useMutation } from "@apollo/client";
import { navigate } from "gatsby";
import _uniqueId from "lodash/uniqueId";

import ChangePhone from "@/components/common/changePhone";
import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";
import PhoneInput from "@/components/common/Inputs/phoneInput";
import { UrlParamKeyEnum } from "@/components/constants";
import { FormTypeEnum } from "@/components/layout/modals/types";
import { setAuthParamToURL } from "@/components/layout/modals/utils";
import ChangePhoneModal from "@/components/profile/account/changePhoneModal";
import UserContext from "@/contexts/User/UserContext";
import { getErrorCode } from "@/utils/apolloUtils";
import { convertToPlainPhone, trimAndLowerCase } from "@/utils/stringUtils";
import { Button, ButtonSizeEnum } from "@components/common/button";
import { changePhone as changePhoneType, changePhoneVariables } from "@components/profile/account/graphql/__generated__/changePhone";
import { CHANGE_PHONE } from "@components/profile/account/graphql/CHANGE_PHONE";

import { PhoneBlockProps } from "./types";
import { getChangePhoneErrorMessage } from "./utils";
import "../styles.scss";

const PhoneBlock = (props: PhoneBlockProps) => {
  const { successHandler, setShowAnimationWithConfetti, changePhoneInSeparateModalFlag = false } = props;
  const { phone, tmpPhone } = useContext(UserContext);
  const [newPhone, setNewPhone] = useState("");
  const [phoneErrorText, setPhoneErrorText] = useState<string | null>(null);
  const [isPhoneUnconfirmed, setIsPhoneUnconfirmed] = useState<boolean>(true);
  const [isPhoneEditing, setIsPhoneEditing] = useState(false);
  const [isChangePhoneShown, setIsChangePhoneShown] = useState<boolean>(false);
  const [
    changePhone,
    { data: changePhoneData, error: changePhoneError, loading: changePhoneLoading },
  ] = useMutation<changePhoneType, changePhoneVariables>(CHANGE_PHONE);

  const [inputUniqueId] = useState(_uniqueId());

  useEffect(() => {
    setIsPhoneEditing(!phone && !tmpPhone);
    setIsPhoneUnconfirmed(!phone);
  }, [phone, tmpPhone]);

  const changePhoneClick = () => {
    setIsPhoneEditing(true);
  };

  const handleSubmit = () => {
    if (phoneErrorText) {
      return;
    }

    if (newPhone !== phone) {
      changePhone({ variables: { newNumber: trimAndLowerCase(newPhone) } });
    }
  };

  useEffect(() => {
    if (changePhoneData && !changePhoneError && !changePhoneLoading) {
      window.carrotquest.identify({ $phone: convertToPlainPhone(trimAndLowerCase(newPhone)) });
      setIsChangePhoneShown(true);
    }
  }, [changePhoneData, changePhoneError, changePhoneLoading, newPhone]);

  const successCallback = useCallback(() => {
    if (setShowAnimationWithConfetti) {
      setShowAnimationWithConfetti(true);
    }
    if (successHandler) {
      successHandler();
    }
    setAuthParamToURL(
      location,
      null,
      changePhoneInSeparateModalFlag ? UrlParamKeyEnum.AccountModal : UrlParamKeyEnum.LoginFormModal,
    );
  }, []);

  const backClickCallback = useCallback(() => {
    if (changePhoneInSeparateModalFlag) {
      setIsChangePhoneShown(false);
    } else {
      navigate(-1);
    }
  }, []);

  return (
    <>
      {(changePhoneInSeparateModalFlag || !isChangePhoneShown)
        && (
        <div className="account__group">
          <div className="input-group">
            <div className="input-group__item login-method">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label className="h4" htmlFor={`phone${inputUniqueId}`}>Телефон</label>
              {isPhoneEditing ? (
                <>
                  <PhoneInput
                    id={`phone${inputUniqueId}`}
                    phoneInitial={phone || tmpPhone || ""}
                    setPrevValueCallback={() => {}}
                    onChange={(changedPhone, errorText) => {
                      setNewPhone(changedPhone);
                      setPhoneErrorText(errorText);
                    }}
                    onSubmit={handleSubmit}
                  />
                  {newPhone && phoneErrorText && (
                  <div className="input-group__error">{phoneErrorText}</div>
                  )}
                  {!!changePhoneError && (
                  <div className="input-group__error">
                    {getChangePhoneErrorMessage(getErrorCode(changePhoneError))}
                  </div>
                  )}
                  <div className="input-group__text">Отправим СМС с кодом подтверждения</div>
                  <div className="login-method__button">
                    <Button
                      text="Получить код в СМС"
                      size={ButtonSizeEnum.Middle}
                      onClick={handleSubmit}
                      disabled={!newPhone
                        || phone === newPhone
                        || !!phoneErrorText
                        || changePhoneLoading}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="login-method__phone">
                    {phone || tmpPhone}
                    &nbsp;&nbsp;
                    <span
                      className="login-method__button"
                      onClick={changePhoneClick}
                      onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
                        event.code === "Enter" && changePhoneClick()}
                      role="button"
                      tabIndex={-1}
                    >
                      Изменить
                    </span>
                  </div>
                  {!!changePhoneError && (
                  <div className="input-group__error">
                    {getChangePhoneErrorMessage(getErrorCode(changePhoneError))}
                  </div>
                  )}
                  {!changePhoneError && !isPhoneUnconfirmed && (
                  <div className="login-method__info login-method__info--valid">
                    <Icon type={IconTypeEnum.Tick} size={IconSizeEnum.Size20} />
                    Подтверждён
                  </div>
                  )}
                  {!changePhoneError && isPhoneUnconfirmed && (
                  <div className="login-method__info login-method__info--invalid">
                    <Icon type={IconTypeEnum.Error} size={IconSizeEnum.Size32} />
                    Нужно подтвердить номер
                  </div>
                  )}
                  {!phone && !isPhoneUnconfirmed && (
                  <div className="login-method__button">
                    Отправим СМС с кодом для подтверждения
                  </div>
                  )}
                  {isPhoneUnconfirmed && (
                  <div
                    className="login-method__button"
                    onClick={() => {
                      if (setShowAnimationWithConfetti) {
                        setShowAnimationWithConfetti(true);
                      }
                      changePhone({ variables: { newNumber: trimAndLowerCase(tmpPhone) } });
                    }}
                  >
                    Отправить СМС для подтверждения
                  </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        )}
      {changePhoneInSeparateModalFlag && isChangePhoneShown && (
        <ChangePhoneModal
          phone={newPhone || tmpPhone || ""}
          successCallback={successCallback}
          backClickCallback={backClickCallback}
          closeCallback={() =>
            setIsPhoneEditing(!phone && !tmpPhone)}
        />
      )}
      {!changePhoneInSeparateModalFlag && isChangePhoneShown && (
        <ChangePhone
          phone={newPhone || tmpPhone || ""}
          successCallback={successCallback}
          backClickCallback={backClickCallback}
          helpClickCallback={() =>
            setAuthParamToURL(window.location, FormTypeEnum.Support)}
          // todo: support setIsPhoneEditing(!phone && !tmpPhone)} on modal close when !changePhoneInSeparateModalFlag
        />
      )}
    </>
  );
};

export default PhoneBlock;
