import React from "react";

import Template from "@layout/Template";
import { LayoutProps } from "@layout/types";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Helmet } from "react-helmet";

import { WebsiteSectionUrl } from "@/components/constants";
import AuthorizationFormContextProvider from "@/contexts/AuthorizationForm";
import GlobalContextProvider from "@/contexts/Global";
import UserContextProvider from "@/contexts/User";
import { isBrowser, isWebView } from "@/utils/env";

import useScrollManagement from "./hooks/useScrollManagement";
import "./styles.scss";

const Layout = ({ children }: LayoutProps) => {
  const staticFileVersion = process.env.GATSBY_STATIC_FILE_VERSION || `1`;

  useScrollManagement({
    pathsToPreservePosition: [
      WebsiteSectionUrl.Catalog,
      WebsiteSectionUrl.Article,
      WebsiteSectionUrl.Category,
      WebsiteSectionUrl.Goroskop,
      WebsiteSectionUrl.Gid,
    ],
    renderedContentPath: children.props.path,
  });

  return (
    <>
      {isBrowser() && (
        <>
          <Helmet>
            {/* These styles are also included in src/styles for faster load */}
            {/* But don't remove them from here% licenses are critical in all pages head */}

            <script type="text/javascript">
              {`
            /**
            * @license
            * MyFonts Webfont Build ID 313421
            *
            * The fonts listed in this notice are subject to the End User License
            * Agreement(s) entered into by the website owner. All other parties are
            * explicitly restricted from using the Licensed Webfonts(s).
            *
            * You may obtain a valid license from one of MyFonts official sites.
            * http://www.fonts.com
            * http://www.myfonts.com
            * http://www.linotype.com
            *
            */
          `}
            </script>
            <link rel="stylesheet" type="text/css" href="/fonts/GilroyBold/MyFontsWebfontsKit.css" />
            <script type="text/javascript">
              {`
            /**
            * @license
            * MyFonts Webfont Build ID 450889
            *
            * The fonts listed in this notice are subject to the End User License
            * Agreement(s) entered into by the website owner. All other parties are
            * explicitly restricted from using the Licensed Webfonts(s).
            *
            * You may obtain a valid license from one of MyFonts official sites.
            * http://www.fonts.com
            * http://www.myfonts.com
            * http://www.linotype.com
            *
            */
          `}
            </script>
            <link rel="stylesheet" type="text/css" href="/fonts/GilroyMedium/MyFontsWebfontsKit.css" />

            <script type="text/javascript">
              {`
            /**
             * @license
            * MyFonts Webfont Build ID 248572
            *
            * The fonts listed in this notice are subject to the End User License
            * Agreement(s) entered into by the website owner. All other parties are
            * explicitly restricted from using the Licensed Webfonts(s).
            *
            * You may obtain a valid license from one of MyFonts official sites.
            * http://www.fonts.com
            * http://www.myfonts.com
            * http://www.linotype.com
            *
            */
          `}
            </script>
            <link rel="stylesheet" type="text/css" href="/fonts/GilroyRegular/MyFontsWebfontsKit.css" />

            <script type="text/javascript">
              {`
            /**
            * @license
            * MyFonts Webfont Build ID 906702
            *
            * The fonts listed in this notice are subject to the End User License
            * Agreement(s) entered into by the website owner. All other parties are
            * explicitly restricted from using the Licensed Webfonts(s).
            *
            * You may obtain a valid license from one of MyFonts official sites.
            * http://www.fonts.com
            * http://www.myfonts.com
            * http://www.linotype.com
            *
            */
          `}
            </script>
            <link rel="stylesheet" type="text/css" href="/fonts/GilroyRegularItalic/MyFontsWebfontsKit.css" />

            <script type="text/javascript">
              {`
            /**
            * @license
            * MyFonts Webfont Build ID 275920
            *
            * The fonts listed in this notice are subject to the End User License
            * Agreement(s) entered into by the website owner. All other parties are
            * explicitly restricted from using the Licensed Webfonts(s).
            *
            * You may obtain a valid license from one of MyFonts official sites.
            * http://www.fonts.com
            * http://www.myfonts.com
            * http://www.linotype.com
            *
            */
          `}
            </script>
            <link rel="stylesheet" type="text/css" href="/fonts/GilroySemiBold/MyFontsWebfontsKit.css" />

            <script type="text/javascript">
              {`
            /**
            * @license
            * MyFonts Webfont Build ID 404664
            *
            * The fonts listed in this notice are subject to the End User License
            * Agreement(s) entered into by the website owner. All other parties are
            * explicitly restricted from using the Licensed Webfonts(s).
            *
            * You may obtain a valid license from one of MyFonts official sites.
            * http://www.fonts.com
            * http://www.myfonts.com
            * http://www.linotype.com
            *
            */
          `}
            </script>
            <link rel="stylesheet" type="text/css" href="/fonts/GilroyUltraLight/MyFontsWebfontsKit.css" />

            <meta
              name="viewport"
              content="width=device-width,  maximum-scale=1.0, minimum-scale=1.0, initial-scale=1, user-scalable=0, shrink-to-fit=true"
            />
            <script>
              {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-K85495H');
            `}
            </script>
            <script src="https://widget.cloudpayments.ru/bundles/cloudpayments.js" />
            <script>
              {`mindbox = window.mindbox || function() { mindbox.queue.push(arguments); };
            mindbox.queue = mindbox.queue || [];
            mindbox('create', {
                firebaseMessagingSenderId: "720806510059"
            });
            mindbox("webpush.create");`}
            </script>
            <script src="https://api.mindbox.ru/scripts/v1/tracker.js" async />
            <script type="text/javascript" src="https://cdn.carrotquest.io/api.min.js" />
            <script src="https://app.getreview.io/tags/ceaHMxfoQ0wYd9YD/sdk.js" async />

            <link rel="apple-touch-startup-image" href={`/favicons/256.png?v=${staticFileVersion}`} />
            <meta name="x5-fullscreen" content="true" />
            <meta name="x5-page-mode" content="app" />
            <meta name="browsermode" content="application" />
            <meta name="full-screen" content="yes" />
            <meta property="al:web:url" content="https://lunaro.ru/" />
            <meta name="mobile-web-app-capable" content="yes" />
            <meta name="theme-color" content="#ffffff" />
            <meta name="application-name" content="Lunaro" />
            <meta name="msapplication-starturl" content="https://lunaro.ru/catalog" />
            <meta name="msapplication-navbutton-color" content="#ffffff" />
            <meta name="msapplication-TileImage" content={`https://lunaro.ru/favicons/512.png?v=${staticFileVersion}`} />
            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta name="apple-touch-fullscreen" content="yes" />
            <meta name="apple-mobile-web-app-status-bar-style" content="black" />
            <meta name="apple-mobile-web-app-title" content="Lunaro" />
            <meta name="apple-mobile-web-app-capable" content="yes" />
            <link rel="apple-touch-icon" sizes="180x180" href={`https://lunaro.ru/favicons/180.png?v=${staticFileVersion}`} />
            <link rel="icon" href={`https://lunaro.ru/favicons/fav.svg?v=${staticFileVersion}`} type="image/svg+xml" />

            <meta property="og:site_name" content="Lunaro" />
            <meta property="og:url" content="https://lunaro.ru/" />
            <meta property="og:type" content="website" />

            <meta
              name="yandex-tableau-widget"
              content={`logo=https://lunaro.ru/favicons/ya-tableau.png?v=${staticFileVersion}, color=#000000`}
            />

            {isWebView() && (
            <style>
              {`
                  .dont-show-in-web-view { display: none; }
                `}
            </style>
            )}
          </Helmet>
          <noscript>
            <iframe
              title="googletagmanager"
              src="https://www.googletagmanager.com/ns.html?id=GTM-K85495H"
              height="0"
              width="0"
              style={{
                display: "none",
                visibility: "hidden",
              }}
            />
          </noscript>
        </>
      )}
      {process.env.GATSBY_SUBDOMAIN ? (
        <GoogleReCaptchaProvider reCaptchaKey="6LcJ1hknAAAAAEJXA01qEqgYXOz025TLaPma3MdF">
          <div className="main__container">
            <main className="main">{children}</main>
          </div>
        </GoogleReCaptchaProvider>
      ) : (
        <UserContextProvider>
          <GlobalContextProvider>
            <GoogleReCaptchaProvider reCaptchaKey="6LcJ1hknAAAAAEJXA01qEqgYXOz025TLaPma3MdF">
              <AuthorizationFormContextProvider>
                <Template>{children}</Template>
              </AuthorizationFormContextProvider>
            </GoogleReCaptchaProvider>
          </GlobalContextProvider>
        </UserContextProvider>
      )}
    </>
  );
};

export default Layout;
