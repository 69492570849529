import { IconTypeEnum } from "@/components/common/icon";

import commentsImg1 from "./components/commentsFromClients/images/img-1.png";
import commentsImg2 from "./components/commentsFromClients/images/img-2.png";
import commentsImg3 from "./components/commentsFromClients/images/img-3.png";
import commentsImg4 from "./components/commentsFromClients/images/img-4.png";
import { PopularQuestionsData } from "./types";

export const questions = {
  step1: [
    {
      icon: IconTypeEnum.Smile_1,
      text: "Спокойно",
    },
    {
      icon: IconTypeEnum.Smile_2,
      text: "Тревожно",
    },
    {
      icon: IconTypeEnum.Smile_3,
      text: "Растерянно",
    },
    {
      icon: IconTypeEnum.Smile_4,
      text: "Грустно",
    },
    {
      icon: IconTypeEnum.Smile_5,
      text: "Взволнованно",
    },
    {
      icon: IconTypeEnum.Smile_6,
      text: "Раздражённо",
    },
  ],
  step2: [
    {
      icon: IconTypeEnum.Topic_icon_2,
      text: "Любовь \nи отношения ",
    },
    {
      icon: IconTypeEnum.Topic_icon_1,
      text: `В поисках \nлюбви`,
    },
    {
      icon: IconTypeEnum.Topic_icon_3_V2,
      text: "Моё будущее",
    },
    {
      icon: IconTypeEnum.Topic_icon_4,
      text: "Финансы \nи карьера",
    },
    {
      icon: IconTypeEnum.Topic_icon_5,
      text: "Таланты и предназначение",
    },
    {
      icon: IconTypeEnum.Topic_icon_6,
      text: "Другая\nситуация",
    },
  ],
};

export const popularQuestionsData: PopularQuestionsData = {
  questions_1: {
    categoryName: "Любовь и отношения",
    questions: [
      { id: 1, question: "Как будут развиваться наши отношения?" },
      { id: 2, question: "Что сделать, чтобы наши отношения перешли на новый уровень?" },
      { id: 3, question: "Его истинные чувства и намерения?" },
      { id: 4, question: "Мы поссорились. Стоит ли мне пытаться его/её вернуть? Как мне действовать?" },
      { id: 5, question: `Я сильно переживаю из-за расставания. Почему мы расстались? Есть ли шанс его/её вернуть?` },
      { id: 6, question: "Задам свой вопрос" },
    ],
  },
  questions_2: {
    categoryName: "В поисках любви",
    questions: [
      { id: 1, question: "Скоро ли появятся новые отношения?" },
      { id: 2, question: "Где и когда я встречу свою любовь?" },
      { id: 3, question: "Мне нравится один человек… Взаимны ли мои чувства?" },
      { id: 4, question: "Причины одиночества? Почему не складывается личная жизнь?" },
      { id: 5, question: `Какой будет моя личная жизнь в ближайшее время?` },
      { id: 6, question: "Задам свой вопрос" },
    ],
  },
  questions_3: {
    categoryName: "Моё будущее",
    questions: [
      { id: 1, question: "Мои перспективы в отношениях" },
      { id: 2, question: "Мои перспективы в финансах" },
      { id: 3, question: "Что меня ждёт? Важные изменения в будущем" },
      { id: 4, question: "Мой прогноз на месяц" },
      { id: 5, question: "Мой прогноз на год" },
      { id: 6, question: "Задам свой вопрос" },
    ],
  },
  questions_4: {
    categoryName: "Финансы и карьера",
    questions: [
      { id: 1, question: "Что мне сделать, чтобы выйти на новый уровень в деньгах?" },
      { id: 2, question: "Что мешает мне больше зарабатывать? Как пробить «финансовый потолок»?" },
      { id: 3, question: "Мои перспективы в финансах в ближайшем будущем?" },
      { id: 4, question: "Как мне продвинуться в карьере?" },
      { id: 5, question: "Какие у меня перспективы на текущем месте работы / в моём бизнесе?" },
      { id: 6, question: "Задам свой вопрос" },
    ],
  },
  questions_5: {
    categoryName: "Таланты и предназначение",
    questions: [
      { id: 1, question: "Какие изменения грядут в мою жизнь?" },
      { id: 2, question: "В чём мое истинное предназначение?" },
      { id: 3, question: "В чём мои сильные и слабые стороны? Как реализовать мои таланты?" },
      { id: 4, question: "Какие шаги предпринять, чтобы следовать своему пути?" },
      { id: 5, question: "Как преодолеть кризис и выйти из тупика в жизни?" },
      { id: 6, question: "Задам свой вопрос" },
    ],
  },
  questions_6: {
    categoryName: "Другая ситуация",
    questions: [
      { id: 1, question: "Исполнится ли моё желание?" },
      { id: 2, question: "Что меня ждёт в ближайший месяц?" },
      { id: 3, question: "Как действовать в моей ситуации? Какое решение будет лучшим для меня?" },
      { id: 4, question: "Как исправить ситуацию?" },
      { id: 5, question: "Конкретный ответ «Да/Нет»" },
      { id: 6, question: "Задам свой вопрос" },
    ],
  },
};

export const commentsFromClientsData = [
  {
    id: 1,
    img: commentsImg1,
    clientName: "Имя скрыто",
    rate: 5,
    date: "20 апр 2024",
    comment: "Это было лучшая консультация и именно консультация, где тебя слушают и слышат, с ответами на все вопросы, я много забрала с собой, а особенно есть над чем подумать. Настин-девушка в невероятной энергетикой, её не хотелось отпускать, она очень бережно относится к запросам и искренне помогает разобраться в себе и в ситуации. Всё верно, многое зависит от нас самих, а она показала пути решения и разрешения ситуации. Спасибо большое, я получила огромное удовольствие от консультации. Она была на высоком уровне, обязательно вернусь.",
  },
  {
    id: 2,
    img: commentsImg2,
    clientName: "Имя скрыто",
    rate: 5,
    date: "4 апр 2024",
    comment: "Профессионал с огромным даром работы с людьми, мастер своего дела! Интерпретации карт и снятие информации по каждому вопросу были настолько точными, детальными и связанными с происходящим в моем сознании и процессами в жизни, что очень быстро распаковывалась нужная информация, соединялась с моими ощущениями и получала мощный внутренний отклик. После сессии все сказанное очень легко и без сопротивления переложилось на понимае, как сейчас действовать.",
  },
  {
    id: 3,
    img: commentsImg3,
    clientName: "Имя скрыто",
    rate: 5,
    date: "6 мая 2024",
    comment: "Не первый раз обращаюсь к Кристине! Это самый лучший специалист из всех кого я видела!!! Первый расклад сбылся на 200 % . И этот я уверенна сбудутся на 300% . Кристина нашла свое дело и помогает людям своим светом и добротой. Кристиночка спасибо вам огромные за советы, за то что даете правильные ответы! За то что даете развернутые ответы! Принимаю все что вы сказали и пускаю во вселенную.",
  },
  {
    id: 4,
    img: commentsImg4,
    clientName: "Имя скрыто",
    rate: 5,
    date: "20 фев 2024",
    comment: "Какой сайт здоровский. Спасибо вам большое! Может мне просто повезло с экспертом? Я уже длительное время нахожусь в подавленном состоянии, а Анна зарядила меня позитивом и теплой энергией, за что ей огромное спасибо. Теперь я знаю, в каком направлении мне работать. Обязательно вернусь ещё и конкретно обращусь за советом к Анне. 😍😍😍",
  },
];
