import React, { useContext } from "react";

import { FormTypeEnum } from "@layout/modals/types";

import CashbackDetailsCard from "@/components/common/cashbackDetailsCard";
import Modal from "@/components/common/modal";
import { UrlParamKeyEnum } from "@/components/constants";
import FinanceOnboardingModal from "@/components/modals/financialOnboardingModal";
import OnboardingModal from "@/components/modals/onboardingModal";
import OnboardingModalV2 from "@/components/modals/onboardingModalV2";
import PhoneModal from "@/components/modals/phoneModal";
import QuestionnaireModal from "@/components/modals/questionnaireModal";
import RelationOnboardingModal from "@/components/modals/relationOnboardingModal";
import SupportModal from "@/components/modals/supportModal";
import AuthorizationFormContext from "@/contexts/AuthorizationForm/AuthorizationFormContext";

import "../styles.scss";

/**
 * Modals used in many parts of the code
 * @returns
 */
const CommonModals = () => {
  const { formType } = useContext(AuthorizationFormContext);

  switch (formType) {
    case FormTypeEnum.Questionnaire:
      return (
        <Modal
          urlParamKey={UrlParamKeyEnum.LoginFormModal}
          header="Завершение регистрации"
          className="questionnaire styled-subtitle"
        >
          <QuestionnaireModal enableEmail enableBirthDate />
        </Modal>
      );
    case FormTypeEnum.BirthDateQuestionnaire:
      return (
        <Modal
          urlParamKey={UrlParamKeyEnum.LoginFormModal} // todo: change urlParamKey?
          header="Ваша дата рождения"
          className="questionnaire styled-subtitle"
        >
          <QuestionnaireModal enableBirthDate />
        </Modal>
      );
    case FormTypeEnum.PhoneQuestionnaire:
      return (
        <Modal
          urlParamKey={UrlParamKeyEnum.LoginFormModal} // todo: change urlParamKey?
          header="Укажите номер телефона для связи с экспертом"
          className="questionnaire styled-subtitle"
        >
          <PhoneModal />
        </Modal>
      );
    case FormTypeEnum.CashBackInformation:
      return <CashbackDetailsCard />;
    case FormTypeEnum.Onboarding:
      return <OnboardingModalV2 urlParamKey={UrlParamKeyEnum.LoginFormModal} />;
    case FormTypeEnum.Onboarding2:
      return <OnboardingModal urlParamKey={UrlParamKeyEnum.LoginFormModal} />;
    case FormTypeEnum.RelationOnboarding:
      return <RelationOnboardingModal urlParamKey={UrlParamKeyEnum.LoginFormModal} />;
    case FormTypeEnum.FinanceOnboarding:
      return <FinanceOnboardingModal urlParamKey={UrlParamKeyEnum.LoginFormModal} />;
    case FormTypeEnum.Support:
      return <SupportModal urlParamKey={UrlParamKeyEnum.LoginFormModal} />;
    default:
      return null;
  }
};

export default CommonModals;
