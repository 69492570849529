import React from "react";

import { useQuery } from "@apollo/client";

import { PaymentFormType } from "@/autoGeneratedGlobalTypes";
import { UrlParamKeyEnum } from "@/components/constants";
import { setAuthParamToURL } from "@/components/layout/modals/utils";
import { getPaymentFormDataDescription, getPaymentFormDataDescriptionVariables } from "@/components/profile/paymentForm/graphql/__generated__/getPaymentFormDataDescription";
import { GET_PAYMENT_FORM_DATA_DESCRIPTION } from "@/components/profile/paymentForm/graphql/GET_PAYMENT_FORM_DATA_DESCRIPTION";
import { cleanupFromDangerousTags } from "@/utils/stringUtils";

import { Button, ButtonColorEnum, ButtonSizeEnum } from "../button";
import Modal from "../modal";

import { CashbackDetailsCardProps } from "./types";

import "./styles.scss";

const CashbackDetailsCard = ({ onClick }: CashbackDetailsCardProps) => {
  const { data: dataFormDescription } = useQuery<getPaymentFormDataDescription, getPaymentFormDataDescriptionVariables>(
    GET_PAYMENT_FORM_DATA_DESCRIPTION,
    {
      variables: {
        input: {
          formType: PaymentFormType.profile,
        },
      },
    },
  );

  return (
    <>
      {
        !onClick ? (
          <Modal
            urlParamKey={UrlParamKeyEnum.LoginFormModal}
            header="Кешбэк"
            className="cashback-modal"
          >
            <div className="cashback-details-card">
              <p className="cashback-details-card__description" dangerouslySetInnerHTML={{ __html: cleanupFromDangerousTags(dataFormDescription?.getPaymentFormData.description || "") }} />
              <div className="cashback-details-card__button-block">
                <Button
                  text="Готово"
                  color={ButtonColorEnum.Light}
                  size={ButtonSizeEnum.Small}
                  className="cashback-details-card__button"
                  onClick={() => {
                    setAuthParamToURL(window.location, null);
                  }}
                />
              </div>
            </div>
          </Modal>
        )
          : (
            <div className="cashback-details-card">
              <p className="cashback-details-card__title">Кешбэк</p>
              <p className="cashback-details-card__description" dangerouslySetInnerHTML={{ __html: cleanupFromDangerousTags(dataFormDescription?.getPaymentFormData.description || "") }} />
              <div className="cashback-details-card__button-block">
                <Button
                  text="Готово"
                  color={ButtonColorEnum.Light}
                  size={ButtonSizeEnum.Small}
                  className="cashback-details-card__button"
                  onClick={onClick}
                />
              </div>
            </div>
          )
      }
    </>
  );
};

export default CashbackDetailsCard;
