import React, { useContext } from "react";

import { Button, ButtonIconPositionEnum } from "@/components/common/button";
import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";
import GlobalContext from "@/contexts/Global/GlobalContext";

import { CancelledAlertProps } from "./types";

import "../../styles.scss";

const CancelledAlert = ({ receiver }: CancelledAlertProps) => {
  const { setIsAlertShown, setAlertStatus } = useContext(GlobalContext);

  const clearAlert = () => {
    setAlertStatus(null);
    setIsAlertShown(false);
  };

  return (
    <>
      <div className="modal__top">
        <Icon
          className="modal__close"
          onClick={clearAlert}
          type={IconTypeEnum.Close}
          size={IconSizeEnum.Size32}
        />
      </div>
      <div
        className="modal__content alert__modal"
      >
        <h2 className="h2">Сессия была завершена</h2>
        <div className="alert__modal-buttons">
          <div className="alert__modal-button">
            <Button
              text="Закрыть"
              icon={IconTypeEnum.Right}
              iconPosition={ButtonIconPositionEnum.Right}
              onClick={clearAlert}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CancelledAlert;
