import { isBrowser } from "@/utils/env";

const referral_key = "referral_key";

type CookieObject = {
  [key: string]: string | null; // Каждый ключ - строка, значение - строка или null
};

// Функция для установки значения в cookie
export const setReferralKeyInCookies = (value: string) => {
  if (isBrowser()) {
    document.cookie = `${encodeURIComponent(referral_key)}=${encodeURIComponent(value)}`;
  }
};

// Функция для получения значения из cookies
export const getReferralKeyFromCookies = (): string | null => {
  if (isBrowser()) {
    const cookies: CookieObject = document.cookie.split(";").reduce((acc, cookie) => {
      const [key, value] = cookie.split("=").map((c) =>
        c.trim());
      acc[decodeURIComponent(key)] = decodeURIComponent(value);
      return acc;
    }, {} as CookieObject);

    return cookies[referral_key] || null;
  }

  return null; // Returning null during SSR
};

// Функция для установки значения в cookie
export const removeReferralKeyFromCookies = () => {
  if (isBrowser()) {
    document.cookie = `${encodeURIComponent(referral_key)}=; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  }
};
