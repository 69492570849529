import { IconTypeEnum } from "@/components/common/icon";

import commentsImg1 from "./components/commentsFromClients/images/img-1.png";
import commentsImg2 from "./components/commentsFromClients/images/img-2.png";
import commentsImg3 from "./components/commentsFromClients/images/img-3.png";
import commentsImg4 from "./components/commentsFromClients/images/img-4.png";
import { PopularQuestionsData } from "./types";

export const questions = {
  step1: [
    {
      icon: IconTypeEnum.Smile_1,
      text: "Спокойно",
    },
    {
      icon: IconTypeEnum.Smile_2,
      text: "Тревожно",
    },
    {
      icon: IconTypeEnum.Smile_3,
      text: "Растерянно",
    },
    {
      icon: IconTypeEnum.Smile_4,
      text: "Грустно",
    },
    {
      icon: IconTypeEnum.Smile_5,
      text: "Взволнованно",
    },
    {
      icon: IconTypeEnum.Smile_6,
      text: "Раздражённо",
    },
  ],
  step2: [
    {
      icon: IconTypeEnum.Topic_icon_1,
      text: `В поисках \nлюбви`,
    },
    {
      icon: IconTypeEnum.Topic_icon_2,
      text: "Текущий \nпартнёр",
    },
    {
      icon: IconTypeEnum.Topic_icon_3,
      text: "Прошлые \nотношения",
    },
    {
      icon: IconTypeEnum.Topic_icon_4,
      text: "Бизнес \nи карьера",
    },
    {
      icon: IconTypeEnum.Topic_icon_5,
      text: "Моя личность \nи жизненный путь",
    },
    {
      icon: IconTypeEnum.Topic_icon_6,
      text: "Другой\nвопрос",
    },
  ],
};

export const popularQuestionsData: PopularQuestionsData = {
  questions_1: {
    categoryName: "В поисках любви",
    questions: [
      { id: 1, question: "Скоро ли у меня появятся новые\n отношения?" },
      { id: 2, question: "Как мне привлечь родственную душу?" },
      { id: 3, question: "Какой будет моя личная жизнь в этом \nгоду?" },
      { id: 4, question: "Почему я одинок/а" },
      { id: 5, question: `Мне нравится один человек… Взаимны \nли мои чувства?` },
      { id: 6, question: "Задам свой вопрос" },
    ],
  },
  questions_2: {
    categoryName: "Текущий партнёр",
    questions: [
      { id: 1, question: "Как будут развиваться наши \nотношения?" },
      { id: 2, question: "Что сделать, чтобы наши отношения\n перешли на новый уровень?" },
      { id: 3, question: "Мы поссорились. Как разобраться \nв ситуации и помириться?" },
      { id: 4, question: "Верен ли мне партнёр?" },
      { id: 5, question: `Чего мне следует избегать в\n отношениях? Как мне лучше себя вести?` },
      { id: 6, question: "Задам свой вопрос" },
    ],
  },
  questions_3: {
    categoryName: "Прошлые отношения",
    questions: [
      { id: 1, question: "Я очень сильно переживаю из-за\n расставания. Что мне делать?" },
      { id: 2, question: "Почему мы расстались?" },
      { id: 3, question: "Стоит ли мне пытаться его/её вернуть?\n Как мне действовать?" },
      { id: 4, question: "Сможем ли мы быть просто друзьями?" },
      { id: 5, question: `У меня новые отношения, но я думаю о \nбывшем/ей. В чём причина? Как \nположить этому конец?` },
      { id: 6, question: "Задам свой вопрос" },
    ],
  },
  questions_4: {
    categoryName: "Бизнес и карьера",
    questions: [
      { id: 1, question: "Что мне сделать, чтобы перейти на \nновый этап развития в моём деле?" },
      { id: 2, question: "Что мешает мне больше зарабатывать?" },
      { id: 3, question: "Стоит ли доверять партнёру/коллеге?" },
      { id: 4, question: "Как лучше выстраивать отношения с\n партнёром, коллегой или начальником?" },
      { id: 5, question: `Какие у меня перспективы на текущем\n месте работы / в моём бизнесе?` },
      { id: 6, question: "Задам свой вопрос" },
    ],
  },
  questions_5: {
    categoryName: "Моя личность и жизненный путь",
    questions: [
      { id: 1, question: "В чем мои сильные и слабые стороны?\n Какие качества мне нужно улучшить?" },
      { id: 2, question: "В чём моё истинное предназначение?" },
      { id: 3, question: "Какие изменения грядут в мою жизнь?" },
      { id: 4, question: "Как мне выйти из состояния тупика?" },
      { id: 5, question: `Какой карьерный путь мне выбрать?` },
      { id: 6, question: "Задам свой вопрос" },
    ],
  },
  questions_6: {
    categoryName: "Другой вопрос",
    questions: [
      { id: 1, question: "Когда мне стоит взять отпуск и как его\n провести?" },
      { id: 2, question: "Исполнится ли моё желание?" },
      { id: 3, question: "Что мне сделать, чтобы чувствовать \nсебя лучше?" },
      { id: 4, question: "Что меня ждёт в ближайший месяц?" },
      { id: 5, question: `Как мне благоприятнее действовать\n в моей ситуации?` },
      { id: 6, question: "Задам свой вопрос" },
    ],
  },
};

export const commentsFromClientsData = [
  {
    id: 1,
    img: commentsImg1,
    clientName: "Имя скрыто",
    rate: 5,
    date: "20 апр 2024",
    comment: "Это было лучшая консультация и именно консультация, где тебя слушают и слышат, с ответами на все вопросы, я много забрала с собой, а особенно есть над чем подумать. Настин-девушка в невероятной энергетикой, её не хотелось отпускать, она очень бережно относится к запросам и искренне помогает разобраться в себе и в ситуации. Всё верно, многое зависит от нас самих, а она показала пути решения и разрешения ситуации. Спасибо большое, я получила огромное удовольствие от консультации. Она была на высоком уровне, обязательно вернусь.",
  },
  {
    id: 2,
    img: commentsImg2,
    clientName: "Имя скрыто",
    rate: 5,
    date: "4 апр 2024",
    comment: "Профессионал с огромным даром работы с людьми, мастер своего дела! Интерпретации карт и снятие информации по каждому вопросу были настолько точными, детальными и связанными с происходящим в моем сознании и процессами в жизни, что очень быстро распаковывалась нужная информация, соединялась с моими ощущениями и получала мощный внутренний отклик. После сессии все сказанное очень легко и без сопротивления переложилось на понимае, как сейчас действовать.",
  },
  {
    id: 3,
    img: commentsImg3,
    clientName: "Имя скрыто",
    rate: 5,
    date: "6 мая 2024",
    comment: "Не первый раз обращаюсь к Кристине! Это самый лучший специалист из всех кого я видела!!! Первый расклад сбылся на 200 % . И этот я уверенна сбудутся на 300% . Кристина нашла свое дело и помогает людям своим светом и добротой. Кристиночка спасибо вам огромные за советы, за то что даете правильные ответы! За то что даете развернутые ответы! Принимаю все что вы сказали и пускаю во вселенную.",
  },
  {
    id: 4,
    img: commentsImg4,
    clientName: "Имя скрыто",
    rate: 5,
    date: "20 фев 2024",
    comment: "Какой сайт здоровский. Спасибо вам большое! Может мне просто повезло с экспертом? Я уже длительное время нахожусь в подавленном состоянии, а Анна зарядила меня позитивом и теплой энергией, за что ей огромное спасибо. Теперь я знаю, в каком направлении мне работать. Обязательно вернусь ещё и конкретно обращусь за советом к Анне. 😍😍😍",
  },
];
