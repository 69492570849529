import React, { Fragment } from 'react'
import { CommentCardProps } from './types'
import { Icon, IconSizeEnum, IconTypeEnum } from '@/components/common/icon';
import './styles.scss'

const CommentCard = ({ clientName, date, rate, img, comment }: CommentCardProps) => {
  return (
    <div className='comment-card-container'>
      <div className='comment-card-container__top'>
        <div className='comment-card-container__block-info'>
          <img className='comment-card-container__client-img' src={img} alt="client img" />
          <div className='comment-card-container__block-name'>
            <span className='comment-card-container__name'>{clientName}</span>
            <span className='comment-card-container__date'>{date}</span>
          </div>
        </div>
        <div className='comment-card-container__block-rates'>
          {Array.from({ length: rate }).map((_, index) => {
            return (
              <Fragment key={index}>
                <Icon type={IconTypeEnum.Fav} size={IconSizeEnum.Size20} />
              </Fragment>
            );
          })}
        </div>
      </div>
      <p className='comment-card-container__comment-text'>{comment}</p>
    </div>
  )
}

export default CommentCard
