import React from "react";

import { Icon } from "@components/common/icon";

import "./styles.scss";
import { FOOTER_DATA } from "../constants";

const PaymentInfo = () =>
  (
    <ul className="footer-payment-info">
      {FOOTER_DATA.menus.paymentInfo.items.map((item, index) =>
        (
          <li key={index} className="footer-payment-info__item">
            <Icon type={item.iconType} size={item.iconSize} title={item.title} />
          </li>
        ))}
    </ul>
  );

export default PaymentInfo;
