import React, { Fragment } from "react";

import { questions } from "../../constants";
import OnboadingCard from "../onboadingCard";

import { ONBOARDING_SECOND_QUESTION } from "./constants";
import { QuestionAboutFeelingProps } from "./types";

import "./styles.scss";

const QuestionAboutFeeling = ({
  nextStep,
  feelingAnswer,
  setFeelingAnswer,
  marketingOnboardingAnswersHandler,
}: QuestionAboutFeelingProps) =>
  (
    <div className="question-feeling">
      <p className="onboarding-title">{ONBOARDING_SECOND_QUESTION}</p>
      <div className="question-feeling__block-cards">
        {questions.step1.map((item, index) =>
          (
            <Fragment key={index}>
              <OnboadingCard
                text={item.text}
                iconType={item.icon}
                nextStep={nextStep}
                setClickElementId={setFeelingAnswer}
                idElement={index + 1}
                feelingAnswer={feelingAnswer}
                question={ONBOARDING_SECOND_QUESTION}
                marketingOnboardingAnswersHandler={marketingOnboardingAnswersHandler}
              />
            </Fragment>
          ))}
      </div>
    </div>
  );

export default QuestionAboutFeeling;
