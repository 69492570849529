import React from "react";

import { navigate } from "gatsby";

import { Button, ButtonColorEnum, ButtonSizeEnum } from "@/components/common/button";
import { SUPPORT_DATA } from "@/components/common/support/constants";
import { CONTACTS } from "@/constants";
import { Icon, IconSizeEnum } from "@components/common/icon";

import "./styles.scss";

const LoginTroubles = () => {
  const {
    // wa,
    tg,
    mail,
  } = CONTACTS;

  return (
    <div className="login-troubles-modal">
      <div className="login-troubles-modal__contacts">
        {/* <a title={wa.title} href={wa.link} target="_blank" rel="noreferrer">
          <Icon type={wa.icon} size={IconSizeEnum.Size24} />
          {wa.title}
        </a> */}
        <a className="tg" title={tg.title} href={tg.link} target="_blank" rel="noreferrer">
          <Icon type={tg.icon} size={IconSizeEnum.Size24} />
          {tg.title}
        </a>
        <a title={mail.title} href={`mailto:${SUPPORT_DATA.mail.link}`}>
          <Icon type={mail.icon} size={IconSizeEnum.Size24} />
          {mail.title}
        </a>
      </div>
      <Button
        text="Вернуться ко вводу кода"
        color={ButtonColorEnum.Light}
        size={ButtonSizeEnum.Small}
        onClick={() =>
          navigate(-1)} // todo:  check if this causes code resend and fix this if yes
      />
    </div>
  );
};

export default LoginTroubles;
