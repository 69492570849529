import { ReactElement } from "react";

type ConditionalWrapperProps = {
  condition: boolean;
  wrapper: (children: ReactElement) => ReactElement;
  children: ReactElement;
};

const ConditionalWrapper = ({ condition, wrapper, children }: ConditionalWrapperProps) =>
  (condition ? wrapper(children) : children);

export default ConditionalWrapper;
