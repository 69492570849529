import React, {
  useContext, useEffect, useRef,
} from "react";

import { useLazyQuery } from "@apollo/client";
import { useLocation } from "@reach/router";

import { SessionStatus, SessionType } from "@/autoGeneratedGlobalTypes";
import { InfoStatusEnum } from "@/components/constants";
import GlobalContext from "@/contexts/Global/GlobalContext";
import UserContext from "@/contexts/User/UserContext";

import AlertHoc from "./alertHoc";
import type { getActiveSession } from "./graphql/__generated__/getActiveSession";
import { getMyProfile } from "./graphql/__generated__/getMyProfile";
import { GET_ACTIVE_SESSION } from "./graphql/GET_ACTIVE_SESSION";
import { GET_PROFILE_QUERY } from "./graphql/GET_PROFILE_QUERY";
import { isExcludedPath } from "./utils";

const SessionController = () => {
  const {
    userID,
    setUserID,
    isUserLoggedIn,
    setBalance,
    setFreeMinutesCount,
  } = useContext(UserContext);
  const {
    isGetActiveSessionLoopRunning,
    setIsGetActiveSessionLoopRunning,
    setIsExpert,
    currentSessionData,
    setCurrentSessionData,
    alertStatus,
    setAlertStatus,
    isAlertShown,
    setIsAlertShown,
  } = useContext(GlobalContext);

  const location = useLocation();

  const [getActiveSession, {
    loading: getActiveSessionLoading,
    error: getActiveSessionError,
    data: getActiveSessionData,
  }] = useLazyQuery<getActiveSession>(GET_ACTIVE_SESSION, { fetchPolicy: "no-cache" });//

  const [getProfile, {
    loading: profileLoading,
    error: profileError,
    data: profileData,
  }] = useLazyQuery<getMyProfile>(
    GET_PROFILE_QUERY,
    {
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
    },
  );

  const activeSessionCheckTimer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (isUserLoggedIn) {
      // todo: move getProfile to Template or hook
      if (userID === null) {
        getProfile();
      }
    }
  }, [userID, isUserLoggedIn, getProfile]);

  useEffect(() => {
    const shouldRunSessionLoop = isUserLoggedIn && !isExcludedPath(location.pathname);
    setIsGetActiveSessionLoopRunning(shouldRunSessionLoop);
  }, [isUserLoggedIn, location.pathname, setIsGetActiveSessionLoopRunning]);

  // Requests session data from server
  useEffect(() => {
    if (isGetActiveSessionLoopRunning) {
      if (!activeSessionCheckTimer.current && isUserLoggedIn) {
        getActiveSession();
        activeSessionCheckTimer.current = setInterval(() => {
          getActiveSession();
        }, 10000);
      }
    } else {
      if (activeSessionCheckTimer.current) {
        clearInterval(activeSessionCheckTimer.current);
        activeSessionCheckTimer.current = null;
      }
      setCurrentSessionData(null);
    }
  }, [
    isGetActiveSessionLoopRunning,
    isUserLoggedIn,
    getActiveSession,
    setCurrentSessionData,
  ]);

  useEffect(() =>
    () => {
      if (activeSessionCheckTimer.current) {
        clearInterval(activeSessionCheckTimer.current);
        activeSessionCheckTimer.current = null;
      }
      // if (abortControllerRef.current) {
      //   abortControllerRef.current.abort();
      // }
      setCurrentSessionData(null);
    }, [setCurrentSessionData]);

  // useEffect(() => {
  //   abortControllerRef.current = new window.AbortController();
  // }, []);

  useEffect(() => {
    if (getActiveSessionData && !getActiveSessionLoading && !getActiveSessionError) {
      if (getActiveSessionData.getActiveSession) {
        setCurrentSessionData(getActiveSessionData.getActiveSession);
        setIsAlertShown(true);
      }
    }
  }, [
    getActiveSessionLoading,
    getActiveSessionError,
    getActiveSessionData,
    setIsAlertShown,
    setCurrentSessionData,
  ]);

  useEffect(() => {
    if (getActiveSessionData
      && !getActiveSessionLoading
      && !getActiveSessionError
      && !getActiveSessionData.getActiveSession
    ) {
      if (currentSessionData?.status === SessionStatus.waiting
          && currentSessionData.sessionType === SessionType.VIDEO_CALL
      ) {
        setAlertStatus(InfoStatusEnum.Cancelled);
        setCurrentSessionData(null);
      } else {
        setCurrentSessionData(null);
      }
    }
  }, [
    getActiveSessionLoading,
    getActiveSessionError,
    getActiveSessionData,
    currentSessionData,
    setAlertStatus,
    setCurrentSessionData,
  ]);

  useEffect(() => {
    if (profileData && !profileLoading && !profileError) {
      setUserID(profileData.getMyProfile.user.id);
      setFreeMinutesCount(profileData.getMyProfile.user.freeMinutesLeft);
      setBalance(profileData.getMyProfile.balance);

      if (profileData.getMyProfile.isExpert) {
        setIsExpert(true);
        // todo: run first thing on the site + make refetch
        // todo: + move here all profile and setIsExpert data
      }
    }
  }, [
    profileLoading,
    profileError,
    profileData,
    setUserID,
    setFreeMinutesCount,
    setBalance,
    setIsExpert,
  ]);

  if (
    !isAlertShown
    || (!currentSessionData?.status && !alertStatus)
    || currentSessionData?.sessionType === SessionType.PHONE_CALL
  ) {
    return null;
  }

  return (
    <AlertHoc
      alertStatus={currentSessionData?.status || alertStatus}
      client={currentSessionData?.client}
      expert={currentSessionData?.expert}
      sessionId={currentSessionData?.id}
      sessionType={currentSessionData?.sessionType as SessionType}
    />
  );
};

export default SessionController;
