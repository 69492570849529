import React from "react";

import { navigate } from "gatsby";

import { Button } from "@/components/common/button";

import { FinalModalContentProps } from "./types";
import "./styles.scss";

const FinalModalContent = ({ marketingOnboardingAnswersHandler }: FinalModalContentProps) =>
  (
    <div className="final-modal-content">
      <p className="onboarding-title">Первая консультация бесплатно</p>
      <div className="final-modal-content__block-content">
        <p className="final-modal-content__description">
          Мы ценим конфиденциальность и комфорт
          <br />
          наших клиентов.
          Вы сможете
          {" "}
          <br />
          <span className="final-modal-content__description--bold">
            {" "}
            выключить камеру
          </span>
          {" "}
          перед видеозвонком
        </p>
        <Button
          text="Получить ответ"
          onClick={() => {
            marketingOnboardingAnswersHandler("Получить ответ", "Получить ответ");
            navigate("/catalog", { state: { onboardingFlag: true } });
          }}
        />
      </div>
    </div>
  );

export default FinalModalContent;
