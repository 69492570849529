import React, { useState } from "react";

import { useLocation } from "@reach/router";

import { UrlParamKeyEnum } from "@/components/constants";
import { setAuthParamToURL } from "@/components/layout/modals/utils";
import StepsComponent from "@/components/modals/onboardingModal/modalWithStep/StepsComponent";
import { useMarketingHook } from "@/marketing/marketingHook";

import StepContent from "./components/StepContent";
import ModalWithStep from "./modalWithStep";

import "./styles.scss";

interface OnboardingModalProps {
  urlParamKey: UrlParamKeyEnum
}

const OnboardingModal = ({ urlParamKey }: OnboardingModalProps) => {
  const { marketingOnboardingAnswers } = useMarketingHook();
  const [step, setStep] = useState(1);
  const [feelingAnswerId, setFeelingAnswerId] = useState<number | undefined>();
  const [checkedThemeId, setCheckedThemeId] = useState<number | undefined>();
  const location = useLocation();

  const nextStep = (stepNumber?: number) => {
    if (step < 6) {
      if (stepNumber) {
        setStep(stepNumber);
      } else {
        setStep(step + 1);
      }
    }
  };

  const prevStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const closeModalHandler = () => {
    setStep(1);
    setFeelingAnswerId(undefined);
    setCheckedThemeId(undefined);
    setAuthParamToURL(location, null, urlParamKey);
  };

  const marketingOnboardingAnswersHandler = (question: string = "", answer: string = "") => {
    marketingOnboardingAnswers(`${step} - ${question}`, answer);
  };

  return (
    <ModalWithStep
      prevStep={prevStep}
      closeModalHandler={closeModalHandler}
      step={step}
    >
      <div className="onboarding-modal">
        {step <= 4 && <StepsComponent stepText="Подбираем вам идеального эксперта" step={step} />}
        <StepContent
          step={step}
          checkedThemeId={checkedThemeId}
          nextStep={nextStep}
          setCheckedThemeId={setCheckedThemeId}
          feelingAnswerId={feelingAnswerId}
          setFeelingAnswerId={setFeelingAnswerId}
          marketingOnboardingAnswersHandler={marketingOnboardingAnswersHandler}
        />
      </div>
    </ModalWithStep>
  );
};

export default OnboardingModal;
