import React, { useMemo, useState } from "react";

import { getAccessToken } from "@/account/user";
import { Gender } from "@/autoGeneratedGlobalTypes";
import { BalanceType, UserContextProps } from "@/contexts/User/types";
import UserContext from "@/contexts/User/UserContext";
import { isBrowser } from "@/utils/env";

const UserContextProvider = ({ children }: UserContextProps) => {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState<boolean>(isBrowser() && !!getAccessToken());
  const [userID, setUserID] = useState<number | null>(null);
  const [UUID, setUUID] = useState<string | null>(null);
  const [balance, setBalance] = useState<BalanceType | null>(null);
  const [freeMinutesCount, setFreeMinutesCount] = useState<number | null>(null);
  const [unfinishedPaymentId, setUnfinishedPaymentId] = useState<number | null>(null);
  const [
    paymentFinishedCallback,
    setPaymentFinishedCallback,
  ] = useState<((paymentId: number) => void) | null>(null);
  const [email, setEmail] = useState<string | null>();// todo: remove initial null in other places too
  const [phone, setPhone] = useState<string | null>(null);
  const [tmpPhone, setTmpPhone] = useState<string | null>(null);
  const [name, setName] = useState<string | null>(null);
  const [dateOfBirth, setDateOfBirth] = useState<string | null>(null);
  const [anonymous, setAnonymous] = useState<boolean>(false);
  const [gender, setGender] = useState<Gender | null>(null);
  const [timezone, setTimezone] = useState<string | null>(null);
  const [isEmailVerified, setIsEmailVerified] = useState<boolean | null>(false);
  const [promoUnauthorizetUser, setPromoUnauthorizetUser] = useState<string | null>(null);
  const [clientReferrer, setClientReferrer] = useState<string>("");
  const [savedCardPaymentResultPath,
    setSavedCardPaymentResultPath] = useState<string>("");

  const UserContextValue = useMemo(
    () =>
      ({
        isUserLoggedIn,
        setIsUserLoggedIn,
        userID,
        setUserID,
        UUID,
        setUUID,
        balance,
        setBalance,
        freeMinutesCount,
        setFreeMinutesCount,
        unfinishedPaymentId,
        setUnfinishedPaymentId,
        paymentFinishedCallback,
        setPaymentFinishedCallback,
        email,
        setEmail,
        phone,
        setPhone,
        tmpPhone,
        setTmpPhone,
        isEmailVerified,
        setIsEmailVerified,
        name,
        setName,
        dateOfBirth,
        setDateOfBirth,
        anonymous,
        setAnonymous,
        gender,
        setGender,
        timezone,
        setTimezone,
        promoUnauthorizetUser,
        setPromoUnauthorizetUser,
        clientReferrer,
        setClientReferrer,
        savedCardPaymentResultPath,
        setSavedCardPaymentResultPath,
      }),
    [
      UUID,
      anonymous,
      balance,
      dateOfBirth,
      email,
      freeMinutesCount,
      gender,
      isEmailVerified,
      isUserLoggedIn,
      name,
      paymentFinishedCallback,
      phone,
      tmpPhone,
      timezone,
      unfinishedPaymentId,
      userID,
      promoUnauthorizetUser,
      clientReferrer,
      savedCardPaymentResultPath,
    ],
  );

  return <UserContext.Provider value={UserContextValue}>{children}</UserContext.Provider>;
};

export default UserContextProvider;
