import React from "react";

import { UrlParamKeyEnum } from "@/components/constants";
import { FormTypeEnum } from "@/components/layout/modals/types";
import { setAuthParamToURL } from "@/components/layout/modals/utils";
import { MIN_FREE_MINUTES_COUNT_FOR_IMMEDIATE_CALL } from "@/constants";
import UserContext from "@/contexts/User/UserContext";

export const useOnboardingForClient = () => {
  const { isUserLoggedIn, freeMinutesCount } = React.useContext(UserContext);
  const isOnboardingActive = process.env.GATSBY_IS_ONBOARDING_FOR_CLIENT_ACTIVE === "true";

  const isActiveOnboarding = (!isUserLoggedIn && isOnboardingActive)
    || (isUserLoggedIn && isOnboardingActive && freeMinutesCount === MIN_FREE_MINUTES_COUNT_FOR_IMMEDIATE_CALL);

  const openOnboardingModal = () => {
    setAuthParamToURL(window?.location, FormTypeEnum.Onboarding);
  };
  const linkForOpenOnboarding = `${`/?${UrlParamKeyEnum.LoginFormModal}=${FormTypeEnum.Onboarding}`}`;

  return { isActiveOnboarding, openOnboardingModal, linkForOpenOnboarding };
};

export default useOnboardingForClient;
