export const TITLE_TEXTS_OPTIONS = [
  "Всё готово! Подбираем лучших для вас экспертов",
  "Суперпрофи в нужной вам сфере",
  "Сейчас онлайн и сможет ответить на ваш вопрос",
  "Высокий рейтинг по отзывам постоянных клиентов",
];

export const PROGRESS_DURATION = 8000; // Продолжительность прогресса в миллисекундах (8 секунд)
export const UPDATE_INTERVAL = 100; // Интервал обновления прогресса в миллисекундах
export const TOTAL_STEPS = PROGRESS_DURATION / UPDATE_INTERVAL; // Количество шагов для заполнения до 100%
