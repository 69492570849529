import React, { useEffect, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { ProcessFindResultProps } from "./types";
import CommentsFromClients from "../commentsFromClients";

import "./styles.scss";

const ProcessFindResult = ({ nextStep }: ProcessFindResultProps) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setValue((prevValue) => {
        if (prevValue >= 1) {
          clearInterval(interval);
          return 1;
        }
        return prevValue + 0.04;
      });
    }, 100);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (value === 1) {
      setInterval(() => {
        nextStep();
      }, 2000);
    }
  }, [value]);

  return (
    <div className="process-find-result">
      <p className="process-find-result__title">
        Всё готово! Подбираем лучших для вас
        <br /> экспертов
      </p>
      <div className="process-find-result__progressbar-block">
        <div className="progressbar-background"></div>
        <CircularProgressbar
          value={value}
          maxValue={1}
          text={`${Math.round(value * 100)}%`}
          styles={buildStyles({
            textSize: "22px",
            pathColor: "#A656D0",
            textColor: "#000000",
            trailColor: "#E2C2F4",
            backgroundColor: "transparent",
          })}
        />
      </div>
      <p className="process-find-result__info-text">Анализируем ваши ответы...</p>
      <CommentsFromClients />
    </div>
  );
};

export default ProcessFindResult;
