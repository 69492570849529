import React from "react";

import { Menu } from "@layout/header/Menu";
import UserMenu from "@layout/header/User/UserMenu";

import LogoBlock from "@/components/common/logoBlock";
import { Icon, IconSizeEnum, IconTypeEnum } from "@components/common/icon";
import Support from "@components/common/support";

import "./styles.scss";

export const MenuPopUp = (props: { menuItem: string; hidePopUpMenu: () => void; showMenuPopUp: boolean }) => {
  const { menuItem, hidePopUpMenu, showMenuPopUp } = props;

  function showMenuItem(name: string) {
    switch (name) {
      case "menu":
        return (
          <>
            <LogoBlock className="menu-mobile__popup-logo" />
            <Menu hidePopUpMenu={hidePopUpMenu} />
            <Support />
          </>
        );
      case "profile":
        return (
          <UserMenu profileData={null} hidePopUpMenu={hidePopUpMenu} />
        );
      default:
        return null;
    }
  }

  return (
    <div className={`menu-mobile__popup ${showMenuPopUp ? "show" : ""}`} onMouseDown={hidePopUpMenu}>
      <div
        className="menu-mobile__popup-inner"
        onMouseDown={(e) =>
          e.stopPropagation()}
      >
        <div className="menu-mobile__popup-close-sticky" onMouseDown={hidePopUpMenu}>
          <div className="menu-mobile__popup-close">
            <Icon type={IconTypeEnum.Close} size={IconSizeEnum.Size24} />
          </div>
        </div>
        {showMenuItem(menuItem)}
      </div>
    </div>
  );
};
