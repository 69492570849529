import React, { useContext } from "react";

import { Link } from "gatsby";

import { Button, ButtonColorEnum } from "@/components/common/button";
import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";
import { UserAvatar } from "@/components/common/userAvatar";
import { UserAvatarTypeEnum } from "@/components/common/userAvatar/types";
import UserInfo from "@/components/common/userInfo";
import { CATALOG_PAGE_NAME } from "@/components/constants";
import GlobalContext from "@/contexts/Global/GlobalContext";

import { ReviewEndedAlertProps } from "./types";

import "../../styles.scss";
import "./styles.scss";

const ReviewEndedAlert = ({ receiver }: ReviewEndedAlertProps) => {
  const { setIsAlertShown, setAlertStatus } = useContext(GlobalContext);

  const clearAlert = () => {
    setAlertStatus(null);
    setIsAlertShown(false);
  };

  return (
    <>
      <div className="modal__top" />
      <div
        className="modal__content alert__modal"
      >
        {receiver && (
          <UserInfo
            avatar={(
              <UserAvatar
                mobileImage={receiver.mobileImage}
                desktopImage={receiver.desktopImage}
                name={receiver.name}
                userId={receiver.id}
                type={UserAvatarTypeEnum.Call}
              />
            )}
          >
            <div className="alert__modal-icon">
              <Icon type={IconTypeEnum.CallIncoming} size={IconSizeEnum.Size48} />
            </div>
          </UserInfo>
        )}
        <div className="alert__modal-icon alert-review-ended">
          <Icon type={IconTypeEnum.Tick} size={IconSizeEnum.Size48} />
        </div>
        <h2 className="h2">Спасибо за отзыв!</h2>
        <div className="alert__modal-buttons">
          <div className="alert__modal-button">
            <Link to={`/${CATALOG_PAGE_NAME}`} state={{ showBannerFromFlocktory: true }}>
              <Button text="Готово" onClick={clearAlert} color={ButtonColorEnum.Light} />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReviewEndedAlert;
