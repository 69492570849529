import { isBrowser } from "@/utils/env";

function setCookie(
  key: string,
  value: string,
  expireDays: number | null,
  expireHours: number | null,
  expireMinutes: number | null,
  expireSeconds: number,
): void {
  const expireDate = new Date();
  if (expireDays !== null) expireDate.setDate(expireDate.getDate() + expireDays);
  if (expireHours !== null) expireDate.setHours(expireDate.getHours() + expireHours);
  if (expireMinutes !== null) expireDate.setMinutes(expireDate.getMinutes() + expireMinutes);
  expireDate.setSeconds(expireDate.getSeconds() + expireSeconds);
  document.cookie = `${key}=${encodeURI(value)
  };domain=` + `.${
    window.location.hostname.split(".")[window.location.hostname.split(".").length - 2]
  }.${
    window.location.hostname.split(".")[window.location.hostname.split(".").length - 1]
  };path=/`
  + `;expires=${expireDate.toUTCString()}`;
}

function deleteCookie(name: string): void {
  setCookie(name, "", null, null, null, 1);
}

export const removeCookiesCarrotQuest = (): void => {
  // Удаляем куки Carrotquest
  deleteCookie("carrotquest_auth_token");
  deleteCookie("carrotquest_device_guid");
  deleteCookie("carrotquest_realtime_services_transport");
  deleteCookie("carrotquest_session");
  deleteCookie("carrotquest_session_started");
  deleteCookie("carrotquest_uid");

  // Принудительно обновляем страницу с сервером, пропуская локальный кэш
  if (isBrowser()) {
    window.location.reload();
  }
};
