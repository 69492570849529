import React, { useContext, useEffect, useState } from "react";

import { useMutation } from "@apollo/client";
import {
  sendCode,
  sendCodeVariables,
} from "@layout/modals/graphql/__generated__/sendCode";
import { SEND_CODE } from "@layout/modals/graphql/SEND_CODE";
import {
  ErrorCodeEnum,
  FormTypeEnum,
  LoginChangeForm,
  LoginTypeEnum,
} from "@layout/modals/types";
import { getErrorByCode, setAuthParamToURL } from "@layout/modals/utils";
import { useLocation } from "@reach/router";
import _uniqueId from "lodash/uniqueId";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import { isEmailValid } from "@/components/common/Inputs/emailInput/utils";
import AuthorizationFormContext from "@/contexts/AuthorizationForm/AuthorizationFormContext";
import UserContext from "@/contexts/User/UserContext";
import { useManageUtmData } from "@/hooks/useManageUtmData";
import { getErrorCode } from "@/utils/apolloUtils";
import { trimAndLowerCase } from "@/utils/stringUtils";
import { Button, ButtonColorEnum, ButtonSizeEnum } from "@components/common/button";
import { SEND_OTP_CODE_RETRY_DELAY } from "@components/constants";

const LoginByEmailModal = (props: LoginChangeForm) => {
  const { clientReferrer } = useContext(UserContext);

  const { setPrevValueCallback } = props;
  const location = useLocation();
  const [sendCodeMutation, { data, loading, error }] = useMutation<sendCode, sendCodeVariables>(SEND_CODE);
  const [errorCode, setErrorCode] = useState<number | null>(null);

  const [email, setEmail] = useState("");
  const [isEmailTouched, setIsEmailTouched] = useState<boolean>(false);
  const {
    setLoginType,
    sentCodeTimestamp,
    setSentCodeTimestamp,
  } = useContext(AuthorizationFormContext);
  const [inputUniqueId] = useState(_uniqueId());

  const { getUtmData } = useManageUtmData();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setEmail(value);
    if (errorCode) {
      setErrorCode(null);
    }
  };

  const handleSubmit = async () => {
    if (!isEmailValid(email)) {
      return;
    }
    if (executeRecaptcha) {
      const token = await executeRecaptcha("submit");
      if (token) {
        sendCodeMutation({
          variables: {
            email: trimAndLowerCase(email),
            utm: getUtmData(),
            reCaptcha: token,
            referer: clientReferrer,
          },
        });
      }
    }
  };

  const onKeyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      event.preventDefault();
      setIsEmailTouched(true);
      handleSubmit();
    }
  };

  useEffect(() => {
    if (data && !loading && !error) {
      setErrorCode(null);
      setSentCodeTimestamp(Date.now() + SEND_OTP_CODE_RETRY_DELAY);
      setAuthParamToURL(location, FormTypeEnum.CodeFromMail);
    }
  }, [data, error, loading, location, setSentCodeTimestamp]);

  useEffect(() => {
    if (error) {
      // If we get the StatusCodeTooManyRequests provide user to form with code
      if (getErrorCode(error) === ErrorCodeEnum.StatusCodeTooManyRequests) {
        if (!sentCodeTimestamp) {
          setSentCodeTimestamp(Date.now() + SEND_OTP_CODE_RETRY_DELAY);
        }
        setAuthParamToURL(location, FormTypeEnum.CodeFromMail);
      } else {
        setErrorCode(getErrorCode(error));
      }
    }
  }, [error, location, sentCodeTimestamp, setSentCodeTimestamp]);

  useEffect(() => {
    setLoginType(LoginTypeEnum.LoginByEmail);
  }, [setLoginType]);

  useEffect(() => {
    setPrevValueCallback(email);
  }, [email, setPrevValueCallback]);

  return (
    <>
      <div className="input-group">
        <input
          type="text"
          id={inputUniqueId}
          value={email}
          autoFocus
          onChange={handleChange}
          onKeyDown={onKeyDownHandler}
          onBlur={() =>
            setIsEmailTouched(true)}
          placeholder="maria@mail.ru"
        />

        {errorCode && <div className="input-group__error">{getErrorByCode(errorCode)}</div>}
        {/* todo: reuse EmailBlock */}
        {isEmailTouched && email && !isEmailValid(email) && (
          <div className="input-group__error">Введите e-mail в формате maria@mail.ru</div>
        )}
      </div>
      <Button
        text="Получить код в письме"
        isLoading={loading}
        size={ButtonSizeEnum.Large}
        color={ButtonColorEnum.Dark}
        onClick={handleSubmit}
        disabled={!isEmailValid(email)}
      />
      <div className="auth__text">
        <b onClick={() =>
          setAuthParamToURL(location, FormTypeEnum.LoginAsExpert)}
        >
          Войти как эксперт
        </b>
      </div>
      <div className="auth__text">
        Создавая аккаунт, вы соглашаетесь
        {" "}
        <br />
        {" "}
        с&nbsp;
        <a href="/user-agreement" target="_blank" rel="noreferrer">
          <b>Условиями,</b>
        </a>
        {"\n"}
        <a href="/privacy-policy" target="_blank" rel="noreferrer">
          <b>Политикой конфиденциальности</b>
        </a>
        {"\n"}
        и&nbsp;
        <a href="/rassylka-agree" target="_blank" rel="noreferrer">
          <b>Получением рассылок</b>
        </a>
        {"\n"}
        {process.env.GATSBY_WEBSITE_NAME}
      </div>
    </>
  );
};

export default LoginByEmailModal;
