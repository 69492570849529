import React from "react";

import { StepsComponentProps } from "./types";

import "./styles.scss";

const StepsComponent = ({ stepText, step }: StepsComponentProps) =>
  (
    <div className="step-container">
      {stepText && <p className="step-container__title">{stepText}</p>}
      <div className="step-container__lines">
        {Array.from({ length: 3 }).map((_, index) =>
          (
            <div
              key={index}
              className={`step-container__lines--step-item ${
                step >= index + 1 ? "step-container__lines--active" : ""
              }`}
            />
          ))}
      </div>
    </div>
  );

export default StepsComponent;
