import React from "react";

import { DropdownValue } from "@/components/common/dropdown";
import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";

export const CONTACTS = {
  // todo: uncomment when WA is not blocked
  // wa: { title: `WhatsApp`, icon: `WhatsApp`, link: process.env.GATSBY_SUPPORT_WHATSAPP },
  tg: { title: `Telegram`, icon: `Tg`, link: process.env.GATSBY_SUPPORT_TELEGRAM },
  mail: {
    title: process.env.GATSBY_SUPPORT_EMAIL,
    icon: `Email`,
    link: process.env.GATSBY_SUPPORT_EMAIL,
  },
};

export const EXPERT_RATE_FOR_TEXTS = 70;

export const MIN_FREE_MINUTES_COUNT_FOR_IMMEDIATE_CALL = 7;

export const MIN_SESSION_DURATION = 7;

export const MINDBOX_DEVICE_ID = "mindboxDeviceUUID";
export const DEVICE_UUID = "deviceUUID";

export const COMMON_PREVIEW_IMAGE_URL = "https://storage.yandexcloud.net/all.lunaro.article.images/Frame_270989406_269b785162.png?updated_at=2024-07-01T15:46:14.862Z";

// Перенес значения платёжных опций в глобальный файл constants.tsx,
// так как в коде были дублирования для страницы записи на appointment и profile.

export const CLOUD_PAYMENT_CARD_VALUE: DropdownValue = {
  value: "-1",
  label: (
    <label className="profile-payment__option">
      <Icon type={IconTypeEnum.ProtectedCard} size={IconSizeEnum.Size32} />
      <span>Российской картой (₽)</span>
    </label>
  ),
};

export const CLOUD_PAYMENT_FOREIGN_CARD_VALUE: DropdownValue = {
  value: "-2",
  label: (
    <label className="profile-payment__option">
      <Icon type={IconTypeEnum.ProtectedCard} size={IconSizeEnum.Size32} />
      <span>Иностранной картой (€)</span>
    </label>
  ),
};

export const ROBOKASSA_PAYMENT_CARD_VALUE: DropdownValue = {
  value: "-3",
  label: (
    <label className="profile-payment__option">
      <Icon type={IconTypeEnum.ProtectedCard} size={IconSizeEnum.Size32} />
      <span>Иностранной картой ($)</span>
    </label>
  ),
};

export const SOM_PAYMENT_CARD_VALUE: DropdownValue = {
  value: "-4",
  label: (
    <label className="payment__option">
      <Icon type={IconTypeEnum.ProtectedCard} size={IconSizeEnum.Size32} />
      <span>Иностранной картой ($)</span>
    </label>
  ),
};

export const SOFTLINE_PAYMENT_CARD_VALUE: DropdownValue = {
  value: "-5",
  label: (
    <label className="payment__option">
      <Icon type={IconTypeEnum.ProtectedCard} size={IconSizeEnum.Size32} />
      <span>Иностранной картой (€)</span>
    </label>
  ),
};

export const MAX_DIGITS_AMOUNT = 10;
export const PAYMENT_INITIAL_AMOUNT = 1000;
