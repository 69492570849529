// todo: check if this works
/**
 *
 * @param s String with possible HTML tags
 * @returns Cleaned string
 */
export const cleanupFromTags = (s: string) =>
  s.replace("<[^>]*>", "");

/**
 *
 * @param s String with possible <script> HTML tags
 * @returns Cleaned string
 */
export const cleanupFromDangerousTags = (s: string) =>
  s.replace(/<\/?script.*\/?>(.*<\/script>)?/gi, "");

/**
 *
 * @param userName Our system user name
 * @returns VoxImplant user name
 */
export const convertToViName = (userName: string) =>
  userName.replace("@", "_at_");

/**
 *
 * @param userName VoxImplant user name
 * @returns Our system user name
 */
export const convertToPlatformName = (userName: string) =>
  userName.replace("_at_", "@");

/** Capitalizes the string */
export const capitalize = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

/**
 * trim string and convert letters to lower case
 * @param str
 */
export const trimAndLowerCase = (str: string) =>
  (str ? str.trim().toLowerCase() : str);

/**
 * Converts any phone number to +12345678900 format
 * @param phone Phone in +12345678900, 12345678900, +1 (234) 567-89-00 or any other format
 * @returns
 */
export const convertToPlainPhone = (phone: string) =>
  (phone ? phone.replace(/^(\+)|\D/g, "$1") : phone);

/**
 * Склоняет слово в зависимости от числа
 * @param num Число
 * @param declensions Массив склонений слова вида ['эксперт', 'эксперта', 'экспертов']
 * @returns Слово в правильном склонении
 */
export const declenateWord = (num: number, declensions: string[]) => {
  if (num > 4 && num < 21) {
    return declensions[2];
  }
  if (num % 10 === 1) {
    return declensions[0];
  }
  if ([2, 3, 4].indexOf(num % 10) !== -1) {
    return declensions[1];
  }
  return declensions[2];
};

export const removeHeadingSlash = (str: string) =>
  str.replace(/^\/*/, "");

export const removeTrailingSlash = (str: string) =>
  str.replace(/\/*\?.*$/, "");

/**
 * Removes slash from the start and from the end of a string
 * @param str
 * @returns
 */
export const trimSlashes = (str: string) =>
  str.replace(/^\/|\/$/g, "");

export const removeTrailingSymbols = (str: string) => {
  const pageMatch = str.match(/\/*\?.*(page=[2-9]|\d\d\d*)&*.*$/);
  return str.replace(/\/*\?.*$/, "") + (pageMatch?.[1] ? `?${pageMatch?.[1]}` : ``);
};
