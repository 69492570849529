import React, { useState } from "react";

import { Link, navigate } from "gatsby";

import { DeviceTypeEnum } from "@/globalTypes";
import UseOutsideClick from "@/hooks/useOutsideClick";
import { detectDeviceType, isBrowser } from "@/utils/env";
import { Icon, IconSizeEnum, IconTypeEnum } from "@components/common/icon";

import { MenuItemProps } from "./types";
import "../styles.scss";

const MenuItem = ({ item, hidePopUpMenu }: MenuItemProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const submenu = UseOutsideClick(() => {
    if (item.subItems) {
      setIsOpen((prev) =>
        // State фризится на инициализиронном значении
        // поэтому решаем, показывать или нет в событии его переназначения
        (prev ? !prev : prev));
    }
  }, "mousedown");

  function clickByItem(e: React.MouseEvent, path: string) {
    if (isBrowser()) {
      e.preventDefault();

      if (detectDeviceType() === DeviceTypeEnum.Desktop || !item.subItems) {
        if (hidePopUpMenu) {
          hidePopUpMenu();
        }
        navigate(path);
      } else {
        setIsOpen((prev) =>
          !prev);
      }
    }
  }

  const handleLinkClick = () => {
    if (hidePopUpMenu) {
      hidePopUpMenu();
    }
  };

  return (
    <div className="menu__item" ref={submenu}>
      <a
        className={`menu__item-link ${isOpen ? "active" : ""}`}
        title={item.title}
        href={item.link}
        onClick={(e) =>
          clickByItem(e, item.link)}
      >
        <Icon className="menu__item-icon" type={item.icon} />
        {item.title}
        {item.subItems && item.subItems.length && (
          <Icon
            className="menu__item-link-dropdown"
            type={IconTypeEnum.Dropdown}
            size={IconSizeEnum.Size12}
          />
        )}
      </a>
      {item.subItems && item.subItems.length && (
        <div
          className="menu__submenu"
          onClick={() =>
            setIsOpen(false)}
        >
          <div className="menu__sublist">
            {item.subItems.map((subItem) =>
              (
                <Link
                  className="menu__sub-item"
                  title={subItem.title}
                  to={subItem.link}
                  key={subItem.title}
                  onClick={handleLinkClick}
                >
                  {!!subItem.icon && (
                    <Icon
                      className={`menu__sub-item-icon icon--${subItem.icon.toLowerCase()}`}
                      type={subItem.icon as IconTypeEnum}
                      viewBox={40}
                      size={IconSizeEnum.Size40}
                    />
                  )}
                  <div className="menu__sub-item-text">{subItem.title}</div>
                  <Icon
                    className="menu__sub-item-arrow"
                    type={IconTypeEnum.Right}
                    size={IconSizeEnum.Size24}
                  />
                </Link>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default MenuItem;
