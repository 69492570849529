import React from "react";

import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";
import "./styles.scss";

const ReferralKeyErrorModal = () =>
  (
    <div className="referral-key-error-modal">
      <div className="referral-key-error-modal__modal-icon alert-unavailable__modal-icon">
        <Icon type={IconTypeEnum.Exclamation} size={IconSizeEnum.Size36} />
      </div>
      <h2 className="referral-key-error-modal__title">Ссылка от друга устарела</h2>
      <p className="referral-key-error-modal__text">Попросите у друга новую ссылку</p>
    </div>
  );

export default ReferralKeyErrorModal;
