import React from "react";

import { EmailConfirmationProps } from "@layout/modals/emailConfirmationModals/emailConfirmationModal/types";
import { setAuthParamToURL } from "@layout/modals/utils";
import { useLocation } from "@reach/router";

import { Button, ButtonColorEnum } from "@components/common/button";
import { Icon, IconSizeEnum, IconTypeEnum } from "@components/common/icon";

const EmailConfirmationModal = ({ isSuccess = false }: EmailConfirmationProps) => {
  const location = useLocation();

  const closeHandler = () => {
    setAuthParamToURL(location, null);
  };

  return (
    <div className="auth__email-confirmation">
      {isSuccess
        && (
          <>
            <div className="auth__circle-icon green">
              <Icon type={IconTypeEnum.Tick} size={IconSizeEnum.Size28} />
            </div>
            <div className="h2">Вы успешно подтвердили свой email и завершили регистрацию</div>
          </>
        )}
      {!isSuccess
        && (
          <>
            <div className="auth__circle-icon red">
              <Icon type={IconTypeEnum.Exclamation} size={IconSizeEnum.Size28} />
            </div>
            <div className="h2">Не удалось подтвердить email</div>
            <p>Возможно, время действия ссылки истекло. Попробуйте запросить новое письмо с&nbsp;подтверждением</p>
          </>
        )}
      <Button
        onClick={closeHandler}
        text="Закрыть"
        color={ButtonColorEnum.Light}
      />
    </div>
  );
};

export default EmailConfirmationModal;
