import React, { useContext, useEffect, useState } from "react";

import { useMutation } from "@apollo/client";
import { login, loginVariables } from "@layout/modals/graphql/__generated__/login";
import { LOGIN_MUTATION } from "@layout/modals/graphql/LOGIN_MUTATION";
import { LoginChangeForm, LoginTypeEnum } from "@layout/modals/types";
import { setAuthParamToURL } from "@layout/modals/utils";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";
import _uniqueId from "lodash/uniqueId";

import { getRedirectUrlSessionStorage, setRedirectUrlSessionStorage } from "@/account/redirect";
import { setAccessToken, setRefreshToken } from "@/account/user";
import { isPasswordValid } from "@/account/utils";
import { isEmailValid } from "@/components/common/Inputs/emailInput/utils";
import AuthorizationFormContext from "@/contexts/AuthorizationForm/AuthorizationFormContext";
import UserContext from "@/contexts/User/UserContext";
import { trimAndLowerCase } from "@/utils/stringUtils";
import { Button, ButtonColorEnum, ButtonSizeEnum } from "@components/common/button";
import PasswordInput from "@components/common/passwordInput";

import "./styles.scss";

const OldFormModal = (props: LoginChangeForm) => {
  const { setPrevValueCallback } = props;
  const { setUserID } = useContext(UserContext);
  const location = useLocation();
  const { setLoginType } = useContext(AuthorizationFormContext);
  const [loginUser, { data: loginData, loading: loginLoading, error: loginError }] = useMutation<
    login,
    loginVariables
  >(LOGIN_MUTATION, {
    errorPolicy: "all", // todo: make general behaviour for network errors
  });

  const [email, setEmail] = useState<string>("");
  const [isEmailTouched, setIsEmailTouched] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [isPasswordBeTouched, setIsPasswordBeTouched] = useState<boolean>(false);
  const [isLoginError, setIsLoginError] = useState<boolean>(false);
  const [inputUniqueId] = useState(_uniqueId());

  const handleSubmit = () => {
    if (!isEmailValid(email) || !isPasswordValid(password, false)) {
      return;
    }
    loginUser({
      variables: {
        email: trimAndLowerCase(email),
        password,
      },
    });
  };

  const onKeyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      event.preventDefault();
      setIsEmailTouched(true);
      setIsPasswordBeTouched(true);
      handleSubmit();
    }
  };

  useEffect(() => {
    // todo: maybe replace setLoginType with setAuthParamToURL
    setLoginType(LoginTypeEnum.LoginByEmail);

    return () => {
      setPrevValueCallback(email);
    };
  }, [email, setLoginType, setPrevValueCallback]);

  useEffect(() => {
    if (loginData && !loginLoading && !loginError) {
      if (isLoginError) {
        setIsLoginError(false);
      }

      setUserID(loginData.login.userID);
      setAccessToken(loginData.login.tokens.accessToken);
      setRefreshToken(loginData.login.tokens.refreshToken);

      setAuthParamToURL(location, null);

      if (getRedirectUrlSessionStorage()) {
        const redirectUrl = getRedirectUrlSessionStorage() || "";
        navigate(redirectUrl);
        setRedirectUrlSessionStorage("");
      }
    } else if (loginError) {
      setIsLoginError(true); // todo: check error code
    }
  }, [loginData, loginLoading, loginError, isLoginError, setUserID, location]);

  return (
    <div className="old-form-modal">
      <div className="input-group">
        <div className="input-group__item">
          <label htmlFor={`email${inputUniqueId}`}>Email</label>
          {/* tofo: reuse EmailBlock */}
          <input
            type="email"
            className={isEmailTouched && !isEmailValid(email) ? " error" : ""}
            name="email"
            id={`email${inputUniqueId}`}
            aria-invalid="true"
            value={email}
            autoFocus
            onChange={(event) =>
              setEmail(event.target.value)}
            onBlur={() =>
              setIsEmailTouched(true)}
            onKeyDown={(e) =>
              onKeyDownHandler(e)}
            disabled={loginLoading}
            placeholder="maria@mail.ru"
          />
        </div>
        {isEmailTouched && !email && (
          <div className="input-group__error" role="alert">
            Укажите свой адрес электронной почты
          </div>
        )}
        {isEmailTouched && email && !isEmailValid(email) && (
          <div className="input-group__error" role="alert">
            Введите верный email. Например, maria@gmail.com
          </div>
        )}
      </div>
      <div className="input-group">
        <div className="input-group__item">
          <label htmlFor={`password${inputUniqueId}`}>Пароль</label>
          <PasswordInput
            className={isPasswordBeTouched && !isPasswordValid(password, false) ? " error" : ""}
            name="password"
            id={`password${inputUniqueId}`}
            aria-invalid="true"
            value={password}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setPassword(e.target.value)}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
              onKeyDownHandler(e)}
            onBlur={() =>
              setIsPasswordBeTouched(true)}
            disabled={loginLoading}
          />
        </div>
        {isPasswordBeTouched && !password && (
          <div className="input-group__error" role="alert">
            Укажите пароль. Не менее 6 символов
          </div>
        )}
        {isPasswordBeTouched && password && password.length < 6 && (
          <div className="input-group__error" role="alert">
            Слишком короткий пароль. Он должен содержать не&nbsp;менее 6&nbsp;символов
          </div>
        )}
        {loginError && (
          <div className="input-group__error" role="alert">
            Неверный пароль
          </div>
        )}
      </div>
      <Button
        className="auth__submit"
        type="submit"
        text="Войти"
        size={ButtonSizeEnum.Middle}
        color={ButtonColorEnum.Dark}
        onClick={handleSubmit}
        disabled={!isEmailValid(email) || !isPasswordValid(password, true)}
      />
    </div>
  );
};

export default OldFormModal;
