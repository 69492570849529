import React, { useMemo, useState } from "react";

import { InfoStatusEnum } from "@/components/constants";
import { getActiveSession_getActiveSession } from "@/components/layout/sessionController/graphql/__generated__/getActiveSession";
import GlobalContext from "@/contexts/Global/GlobalContext";

import { GlobalContextProps } from "./types";

const GlobalContextProvider = ({ children }: GlobalContextProps) => {
  const [isAlertShown, setIsAlertShown] = useState(false);
  const [alertStatus, setAlertStatus] = useState<InfoStatusEnum | null>(null);
  const [
    currentSessionData,
    setCurrentSessionData,
  ] = useState<getActiveSession_getActiveSession | null>();
  const [
    isGetActiveSessionLoopRunning,
    setIsGetActiveSessionLoopRunning,
  ] = useState(false);
  const [isExpert, setIsExpert] = useState<boolean>(false);

  const GlobalContextValue = useMemo(
    () =>
      ({
        isAlertShown,
        setIsAlertShown,
        currentSessionData,
        setCurrentSessionData,
        alertStatus,
        setAlertStatus,
        isGetActiveSessionLoopRunning,
        setIsGetActiveSessionLoopRunning,
        isExpert,
        setIsExpert,
      }),
    [currentSessionData, alertStatus, isAlertShown, isExpert, isGetActiveSessionLoopRunning],
  );

  return <GlobalContext.Provider value={GlobalContextValue}>{children}</GlobalContext.Provider>;
};

export default GlobalContextProvider;
