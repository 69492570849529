import React from "react";

import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";

import "./styles.scss";

const WelcomeModal = () =>
  (
    <>
      <div className="welcome-modal__success-circle">
        <Icon type={IconTypeEnum.Tick} size={IconSizeEnum.Size24} />
      </div>
      <h2 className="welcome-modal__success-header">
        Добро пожаловать
        <br />
        на Lunaro!
      </h2>
      <div className="welcome-modal__gift">
        <Icon type={IconTypeEnum.Gift} size={IconSizeEnum.Size20} />
        Первая консультация по&nbsp;видео бесплатно
      </div>
    </>
  );

export default WelcomeModal;
