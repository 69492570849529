import React, { Fragment } from 'react'
import { commentsFromClientsData } from '../../constants'
import CommentCard from './commentCard'
import "./styles.scss"

const CommentsFromClients = () => {
  return (
    <div className='comments-from-clients-container'>
      <p className='comments-from-clients-container__title'>
        <span>93 тысячи клиентов</span> <br />
        выбирают Lunaro
      </p>
      <div>
        {commentsFromClientsData.map(({ clientName, comment, date, id, img, rate }) => {
          return (
            <Fragment key={id}>
              <CommentCard clientName={clientName} date={date} rate={rate} img={img} comment={comment} />
            </Fragment>
          )
        })}
      </div>
    </div>
  )
}

export default CommentsFromClients
