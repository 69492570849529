import React, { useEffect, useState } from "react";

import { useMutation } from "@apollo/client";

import DigitCodeInput from "@/components/common/Inputs/digitCodeInput";
import { getInitiateCodeState, isCodeLengthValid } from "@/components/common/Inputs/digitCodeInput/utils";
import Timer from "@/components/common/Timer";
import { UrlParamKeyEnum } from "@/components/constants";
import { confirmPhone as confirmPhoneType } from "@/components/profile/account/graphql/__generated__/confirmPhone";
import { CONFIRM_PHONE } from "@/components/profile/account/graphql/CONFIRM_PHONE";
import { REQUEST_CONFIRM_PHONE } from "@/components/profile/account/graphql/REQUEST_CONFIRM_PHONE";
import { getErrorCode } from "@/utils/apolloUtils";
import { SEND_OTP_CODE_RETRY_DELAY } from "@components/constants";
import { requestConfirmPhone as requestConfirmPhoneType } from "@components/profile/account/graphql/__generated__/requestConfirmPhone";
import { getConfirmPhoneErrorMessage, getRequestConfirmPhoneErrorMessage } from "@components/profile/account/loginTypeBlocks/phoneBlock/utils";
import { ChangePhoneProps } from "./types";

import "./styles.scss";

const ChangePhone = ({ phone, successCallback, helpClickCallback, backClickCallback }: ChangePhoneProps) => {
  const [code, setCode] = useState<string>(getInitiateCodeState());
  const [isUpdateCodeLinkShown, setIsUpdateCodeLinkShown] = useState(false);

  const [
    requestConfirmPhone,
    {
      error: requestConfirmPhoneError,
    },
  ] = useMutation<requestConfirmPhoneType>(REQUEST_CONFIRM_PHONE);

  const [
    confirmPhone,
    {
      data: confirmPhoneData,
      loading: confirmPhoneLoading,
      error: confirmPhoneError,
    },
  ] = useMutation<confirmPhoneType>(CONFIRM_PHONE);

  useEffect(() => {
    requestConfirmPhone({ variables: { phone } });
  }, [phone, requestConfirmPhone]);

  useEffect(() => {
    if (!isCodeLengthValid(code)) {
      return;
    }
    confirmPhone({ variables: { code } });
  }, [code, confirmPhone]);

  useEffect(() => {
    if (confirmPhoneData && !confirmPhoneLoading && !confirmPhoneError) {
      if (successCallback) {
        successCallback();
      }
    }
  }, [confirmPhoneData, confirmPhoneError, confirmPhoneLoading, successCallback]);

  const checkTimer = (value: number) => {
    if (!isUpdateCodeLinkShown && value >= 0) {
      setIsUpdateCodeLinkShown(true);
    }
  };

  return (
    <div className="change-phone-modals">
      <DigitCodeInput
        code={code}
        setCode={setCode}
        disabled={confirmPhoneLoading}
      />
      {requestConfirmPhoneError
        && (
        <div className="change-phone-modals__error">
          {getRequestConfirmPhoneErrorMessage(
            getErrorCode(requestConfirmPhoneError),
          )}
        </div>
        )}
      {confirmPhoneError
        && (
        <div className="change-phone-modals__error">
          {getConfirmPhoneErrorMessage(getErrorCode(confirmPhoneError))}
        </div>
        )}
      {" "}
      <div className="change-phone-modals__text">
        {`Код отправлен на ${phone} `}
        <b
          onClick={backClickCallback}
          className="change-phone-modals__link"
        >
          Изменить
        </b>
      </div>
      <div className="change-phone-modals__text">
        {isUpdateCodeLinkShown ? (
          <b
            onClick={() => {
              setIsUpdateCodeLinkShown(false);
              requestConfirmPhone({ variables: { phone } });
            }}
            className="change-phone-modals__link"
          >
            Получить новый код
          </b>
        ) : (
          <>
            Получить новый код можно через&nbsp;
            <Timer
              timestamp={Date.now() + SEND_OTP_CODE_RETRY_DELAY}
              valueCallback={checkTimer}
            />
          </>
        )}
      </div>
      <div className="change-phone-modals__text">
        <b onClick={helpClickCallback} className="change-phone-modals__link">
          Проблемы с получением кода?
        </b>
      </div>
    </div>
  );
};

export default ChangePhone;
