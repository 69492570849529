import { IconSizeEnum, IconTypeEnum } from "@/components/common/icon";

const socialLinks = [
  { title: `Vk`, link: `https://vk.com/lunaro_ru` },
  { title: `Ok`, link: `https://ok.ru/lunaro` },
  { title: `Tg`, link: `https://t.me/lunaroru` },
  { title: `Dz`, link: `https://zen.yandex.ru/lunaro` },
];

const knowledge = {
  title: `Знания`,
  items: [
    { title: `Курсы Таро`, link: `/category/obuchenie-taro` },
    { title: `Гид по картам Таро`, link: `/articles/gid-po-kartam-taro` },
    { title: `Гид по Манаре`, link: `/articles/gid-po-kartam-taro/manara` },
    { title: `Гадание на картах Таро`, link: `/category/gadanie/na-kartah-taro` },
    { title: `Гид по знакам зодиака`, link: `/gidy/zodiak` },
    { title: `Гороскопы`, link: `/goroskop` },
    { title: `Сонник`, link: `/gidy/sonnik` },
  ],
};

const aboutUs = {
  title: `О нас`,
  items: [
    { title: `О сервисе`, link: `/about` },
    { title: `Гарантии`, link: `/guarantees` },
    { title: `Цены`, link: `/prices` },
    { title: `Отзывы`, link: `/otzyvy` },
    { title: `Мы в СМИ`, link: `/smi` },
  ],
};

const benefits = {
  title: `Польза`,
  items: [
    { title: `Бонусы`, link: `/bonuses` },
    { title: `Промокоды`, link: `/promo` },
    { title: `Подарочные сертификаты`, link: `/landing/gift-card` },
    { title: `Партнёрская программа`, link: `https://partner.lunaro.ru/` },
  ],
};

const paymentInfo = {
  title: `Способы оплаты`,
  items: [
    { iconType: IconTypeEnum.PayMastercard, iconSize: IconSizeEnum.Size28, title: `Mastercard` },
    { iconType: IconTypeEnum.PayVisa, iconSize: IconSizeEnum.Size32, title: `Visa` },
    { iconType: IconTypeEnum.PayMir, iconSize: IconSizeEnum.Size32, title: `Мир` },
    { iconType: IconTypeEnum.PayYandex, iconSize: IconSizeEnum.Size36, title: `Yandex Pay` },
  ],
};

const beExpert = {
  title: `Ищем экспертов в эзотерике`,
  anchor: {
    title: `hello@lunaro.ru`,
    link: `mailto:hello@lunaro.ru`,
  },
  description: `Напишите нам о\u00a0себе и\u00a0своём опыте`,
  details: { title: `Узнать подробнее`, link: `/ekspertam` },
};

const bottomData = {
  agreement: {
    title: `Пользовательское соглашение`,
    name: `Пользовательское соглашение`,
    link: `/user-agreement`,
  },
  confidence: {
    title: `Политика конфиденциальности`,
    name: `Политику конфиденциальности`,
    link: `/privacy-policy`,
  },
  referral: {
    title: `Правила реферальной программы`,
    name: `Правила реферальной программы`,
    link: "/referral.pdf",
  },
  cookie: {
    title: `Политика cookie`,
    name: `Политику cookie`,
    link: `/cookie-policy`,
  },
  mailings:{
    title: `Получение рассылки`,
    name: `Получение рассылки`,
    link: `/rassylka-agree`,
  },
  copyright: `© Lunaro, ${new Date().getFullYear()}`,
};

export const FOOTER_DATA = {
  logoSubtitle: `Онлайн-консультации с\u00a0экспертами в\u00a0области эзотерики`,
  socialLinks,
  menus: {
    aboutUs,
    benefits,
    paymentInfo,
    knowledge,
  },
  beExpert,
  bottomData,
};
