import { isBrowser } from "@/utils/env";
import { GOROSKOP_LEAD_FORM_SENT_ID, LEAD_FORM_SENT_ID } from "./constants";

export const isPasswordValid = (password: string, checkLength = true) =>
  password && (!checkLength || password.length >= 6);


export const getLeadFormSentValue = () => {
  if (isBrowser()) return localStorage.getItem(LEAD_FORM_SENT_ID);
  return null;
};

export const setLeadFormSentValue = (value: string) => {
  if (isBrowser()) return localStorage.setItem(LEAD_FORM_SENT_ID, value);
  return null;
};

export const getGoroskopLeadFormSentValue = () => {
  if (isBrowser()) return localStorage.getItem(GOROSKOP_LEAD_FORM_SENT_ID);
  return null;
};

export const setGoroskopLeadFormSentValue = (value: string) => {
  if (isBrowser()) return localStorage.setItem(GOROSKOP_LEAD_FORM_SENT_ID, value);
  return null;
};